import { useEffect, useMemo, useState } from "react";
import { TextField } from "@mui/material";
import { inputFieldSizes, inputFieldTypes } from "./part-utils";
import { numberHelper } from "helpers";
import { IFormFieldInputProps } from "./part-types";

const FormCurrencyField = ({ field, initialValue, onChanged, dataProps } : IFormFieldInputProps ) => {
  // const [valueKey, initialValue] = useAttorneyFormValue(field.id, sectionId, rowIndex);
  // const onFieldChanged = useFieldChanged(valueKey, field.id, rowIndex);
  const [val, setVal] = useState<number | undefined>(initialValue ?? undefined);

  const classNames = `field-${field.size ? field.size : "sm"} field-${field.type}`;
  const width = inputFieldSizes[field.size ?? "sm"];
  const sx = inputFieldTypes[field.type ?? "text"];

  //=== watch the initialValue. It may change if this is a sameAs field, or there's some other dependency
  useEffect(() => {
    setVal(initialValue);
  }, [initialValue]);

  const displayValue = useMemo(() => {
    if(field.calculation){
      //TODO: deal with calculations - check input-field.js, currency case
    }
    return numberHelper.asCurrency(val);
  }, [field.calculation, val]);

  const handleChange = (e: any) => {
    const newVal = numberHelper.asFloat(e.target.value);
    setVal(newVal);
  };

  const handleBlur = (e: any) => {
    if(onChanged && val !== initialValue){
      onChanged(val);
    }
  };

  return (
    <TextField 
      onChange={handleChange}
      onKeyDown={numberHelper.onlyNumbers}
      onBlur={handleBlur}
      id={field.id}
      className={classNames} 
      value={displayValue} 
      size="small"
      {...dataProps}  
      placeholder={field.label} 
      autoComplete="off" 
      inputProps={{ 
        "data-type": "currency",
        style: { 
          fontSize: "14px",
          padding: "6px 12px", 
        } 
      }}
      sx={{
        width: width,
        ...sx,
      }}      
    />
  );

};

export default FormCurrencyField;
