import SHA256 from "crypto-js/sha256";
import _ from "lodash";
import { Comment, CommentWithOwner, FormField, SameAsConfig, Share, ShareStatus } from "types";

export const getShareStatus = (share?: Share | null) => {
  if(!share) return null;
  if(share.sharer) return ShareStatus.ACCEPTED;
  else if(share.invitedAt) return ShareStatus.INVITED;
  else if(share.rejectedAt) return ShareStatus.REJECTED;
  else return ShareStatus.NOT_INVITED;    
};

const refreshSeconds = 8;
export const REFRESH_INTERVAL = 1000 * refreshSeconds; //10 seconds

//===
// Flattens all the comments in a conversation to be organized by field
export const flattenComments = (comments: Comment[], addOwnerFn: (comment: Comment) => any) => {
  const filtered = comments.filter(c => !!c.fieldKey).map(c => ({ ...c, ...addOwnerFn(c) } as CommentWithOwner));  //only pay attention to comments with a fieldKey
  const ordered = filtered.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  
  //Group the comments by field
  const commentsByField: Record<string, CommentWithOwner[]> = ordered.reduce((acc, comment) => {
    if (!acc[comment.fieldKey]) acc[comment.fieldKey] = [comment];
    else acc[comment.fieldKey].push(comment);
    return acc;
  }, {} as Record<string, CommentWithOwner[]>);

  return commentsByField;
};

export const hashString = (value: string)  => {
  return SHA256(value).toString();
}

export const initializeProps = (item: Record<string, any>, fields: string[]) => {
  const obj = fields.reduce((acc, key) => {
    acc[key] = item[key] ?? "";
    return acc;
  }, {} as Record<string, any>);
  return obj;
};

//remove all props of an object that are undefined only
export const removeUndefinedProps = (obj: any) => _.omitBy(obj, _.isUndefined);
export const replaceUndefinedProps = (obj: any, replacement: any = null) => _.mapValues(obj, v => v === undefined ? replacement : v);

//removes all blank array items from the object's properties (only affects properties that are arrays)
export const removeBlankArrayItems = (obj: Record<string, any>) => {
  const result = Object.keys(obj).reduce((acc: any, key: string) => {
    const value = obj[key];
    if(Array.isArray(value)){
      acc[key] = value.filter((v: any) => v !== null);
    }
    else acc[key] = value;
    return acc;
  }, {});

  return result;
};


export const sameAsFlag = (field: SameAsConfig) => {
  return `sa_${field.id}`;
};

export const isSameAs = (field: SameAsConfig, values: Record<string, any>) => {
  const saField = field.sameAs;
  if(!saField) return false;
  const saFlag = sameAsFlag(field);
  return !!values[saFlag];
};

export const sameAs = (field: SameAsConfig, values: Record<string, any>): [boolean, string | null, any, string] => {
  if(!field || !values) throw new Error("Field and values are required for same as check");

  if(isSameAs(field, values)){
    const sameAsValue = values[field.sameAs!];
    return [true, field.sameAs!, sameAsValue, field.sameAsLabel ?? "Same as Client 1"];
  }
  return [false, null, null, ""];
}

