import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useToggle } from "react-use";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Alert, Box, Button, ButtonGroup, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { ArrowBack, CheckCircle, CloudDownload, CloudUpload, CloudUploadOutlined, DeleteOutlined, Edit, FilePresent } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useStatus } from "redux-action-status";
import { ClientDocument, ProviderInfo } from "types";
import { StatusKeys } from "helpers";
import SettingKeys from "helpers/setting-keys";
import { AvatarIcon, ConfirmDialog } from "components";
import { ShareProvider, useShareContext } from "../app/share-context";
import { useAppContext } from "../app/app-context";
import MyConversation from "../my/my-conversations/my-conversation";
import DocumentPropertiesTable from "./document-properties-table";
import { ConversationProvider } from "components/values-table/conversation-context";
import { submitDocument } from "store/actions/petitioner-actions";
import { deleteDocument, downloadDocument, touchedDocument } from "store/actions/share-actions";
import { chooseConversation } from "store/actions/conversation-actions";
import UploadDocumentDialog from "../collaborator/parts/client-documents-card/upload-document-dialog";
// import DocumentVersionUploadDialog from "./upload-document-version-dialog";
// import UploadDocumentDialog from "../collaborator/parts/client-documents-card/upload-document-dialog";

const DocumentViewCore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { documentId } = useParams<{ documentId: string, shareId: string }>();
  const { settingJsonValue, saveSetting, allowComments } = useAppContext();
  const { share } = useShareContext();
  const providers = useSelector<any, ProviderInfo[]>(state => state.petitioner.providers);
  const { isInitialized } = useStatus(StatusKeys.conversations);
  const [isConfirmingRemove, toggleConfirmRemove] = useToggle(false);
  const [toEdit, setToEdit] = useState<ClientDocument | null>(null);

  const document = useMemo(() => share?.documents?.find(d => d.id === documentId), [share?.documents, documentId]);
  const person = useMemo(() => {
    const provider = providers.find(p => p.id === share?.accountId);
    return provider ? provider.name : "Your Attorney";
  }, [providers, share?.accountId]);

  const dismissed = useMemo(() => settingJsonValue(SettingKeys.app.documents.dismissedAlerts, {}), [settingJsonValue]);
  const isDismissed = dismissed ? dismissed[documentId!] ?? false : false;
  
  const isClientUpload = document?.direction === "fromClient";
  const direction = (isClientUpload ? "For" : "From");

  const [isUploading, setIsUploading] = useState(false);

  //Flag that this user has viewed this document
  useEffect(() => {
    if(share?.id) dispatch(touchedDocument(share?.id, documentId));
  }, []);

  //Select the conversation for this document
  useEffect(() => {
    if(share?.id && document?.id && isInitialized) dispatch(chooseConversation(share.id, document.id, "d"));
  }, [isInitialized, dispatch, share?.id, document?.id]);


  if(!document) return null;

  const onGoBack = () => {
    const url = isClientUpload ? "/app/uploads" : "/app";
    navigate(url);
  }

  const handleDownload = async () => {
    const result = await dispatch(downloadDocument(document)) as any;
    if(result.isOk) await dispatch(touchedDocument(share!.id, document.id, true, true));
    toast("Document downloaded successfully", { type: "success" });
  };

  const handleApprove = async () => {
    const result = await dispatch(submitDocument(share!.id, document.id)) as any;
    if(result.isOk){
      toast("Document approved", { type: "success" });
    }
  };

  const handleArchive = async () => {
    console.log("TODO: Archive document");
  };
  
  const handleEdit = async () => {
    setToEdit(document);
  };

  const handleRemove = async () => {
    if(!share) return;
    toggleConfirmRemove();
    const result = await dispatch(deleteDocument(share.id, document.id));
    if((result as any).isOk){
      toast.success("File removed successfully");
      onGoBack();
    }
  };

  const handleDismissAlert = () => {
    const newValue = JSON.stringify({ ...dismissed, [documentId!]: true });
    saveSetting(SettingKeys.app.documents.dismissedAlerts, newValue);
  };

  const message = "Your attorney has requested that you review this document. Use the chat below to ask questions or request changes. Once you are satisfied, click Approve to notify your attorney that you are happy with the document.";

  return (
    <Grid id="document-view" container px={4} pt={2}>
      
      {/* A notification at the top with instructions */}
      {!isClientUpload && !isDismissed && (
        <Alert severity="info" sx={{ mb: 2 }}>
          {message}
          <Grid container mt={2} justifyContent="flex-end">
            <Button onClick={handleDismissAlert} size="small" variant="outlined" color="primary">Dismiss</Button>
          </Grid>
        </Alert>
      )}

      {/* Header */}
      <Grid id="header-grid" container xs={12} justifyContent="flex-start" alignItems="flex-start">
        <Grid sm={9} container flexWrap="nowrap" alignItems="flex-start" gap={2}>
          <Box>
            <Tooltip title="Go back">
              <IconButton size="small" onClick={onGoBack}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
          </Box>

          {/* Document name and info */}
          <Stack >
            <Typography variant="h6" color="primary">{document.name}</Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" textTransform="uppercase" color="grey.500">{isClientUpload ? "upload" : "document"}</Typography>
              <Typography variant="body2" textTransform="uppercase" color="grey.500">{direction}</Typography>
              <Typography variant="body2" textTransform="uppercase" color="primary.main">{person}</Typography>
            </Stack>
          </Stack>
        </Grid>

        {/* The Avatar icon */}
        <Grid sm={3} container alignItems="center" justifyContent="flex-end">
          { isClientUpload && <AvatarIcon size="lg" icon={ <CloudUpload /> } /> }
          { !isClientUpload && <AvatarIcon size="lg" icon={ <FilePresent /> } /> }          
        </Grid> 
      </Grid>

      <Grid id="body-grid" container xs={12} mt={2}>
        <Grid container sm={6} md={5} lg={4}>
          <DocumentPropertiesTable document={document} />
        </Grid>

        <Grid container sm={6} md={7} lg={8} px={2} gap={2}>
          <Grid container sm={12} gap={2} alignItems="flex-start">
            <Button onClick={handleDownload} size="small" variant="outlined" color="primary" startIcon={<CloudDownload fontSize="small" />}>Download</Button>
            {!isClientUpload && (
              <Button onClick={handleApprove} disabled={!!document.submittedAt} size="small" variant="outlined" color="secondary" startIcon={<CheckCircle fontSize="small" />}>Approve</Button>  
            )}
            {isClientUpload && (
              <ButtonGroup variant="outlined" size="small">
                <Button onClick={handleEdit} size="small" variant="outlined" startIcon={<Edit fontSize="small" />}>Edit</Button>
                <Tooltip title="Remove this file">
                  <Button onClick={() => toggleConfirmRemove()} size="small" variant="outlined"><DeleteOutlined fontSize="small" /></Button>
                </Tooltip>

                {/* TODO: wire up the new version functionality for client uploads */}
                {/* <Tooltip title="Upload a new version of this file">
                  <Button onClick={() => setIsUploading(true)} size="small" variant="outlined"><CloudUploadOutlined fontSize="small" /></Button>            
                </Tooltip> */}

              </ButtonGroup>              
            )}
          </Grid>

          {allowComments && (
            <Grid container sm={12}>
              <Paper variant="outlined" sx={{ width: "100%", minHeight: 500, pb: 1 }}>
                <MyConversation />
              </Paper>
            </Grid>
          )}
            
        </Grid>
      </Grid>
      
      {/* <DocumentVersionUploadDialog open={isUploading} onClose={() => setIsUploading(false)} document={document} /> */}
      {toEdit && <UploadDocumentDialog open={!!toEdit} onClose={() => setToEdit(null)} toEdit={toEdit} /> }
      {isConfirmingRemove && <ConfirmDialog open={isConfirmingRemove} onConfirm={handleRemove} onClose={toggleConfirmRemove} title="Remove this file" message="Are you sure you want to remove this file?" />}
    </Grid>
  );
};

const PetitionerDocumentView = () => {
  return (
    <ShareProvider>
      <ConversationProvider>
        <DocumentViewCore />
      </ConversationProvider>
    </ShareProvider>
  );
};

export default PetitionerDocumentView;