import _, { isArray } from 'lodash';
import { VALUE_ACTIONS, APP_ACTIONS } from '../actions/action-types';
import helpers, { DEFAULT_STATUS, workingStatus, finishedStatus, failedStatus } from './reducer-helpers';


const INITIAL_STATE     = {
    isDirty             : false,
    a_isLoaded          : false,
    a_lastModified      : null,   
    reviewerStatus      : {...DEFAULT_STATUS},
    forms: [],
    local           : {
        isLoaded    : false,
        updatedAt   : null,   
        savedAt     : null,
        error       : null,
        isEmpty     : false,
    },

    database        : {
        isLoaded    : false,
        isWorking   : false,
        updatedAt   : null,
        savedAt     : null,   
        error       : null,
    },
};

//----
// Handles a value change for any field.  Called with each keystroke in an input field.
function itemUpdated(state, action){
    let newState = {
        ...state, 
        a_lastModified : helpers.getCurrentDateTime(), 
        isDirty : true 
    };

    const { isManual = false } = action;
    newState = isManual ? updateFieldWithManualRows(newState, action) : updateField(newState, action);
    
    return newState;
}

function updateRegionStatus(state, action){
    const updates = state.forms.find(f => f.id === action.documentId) ?? { id: action.documentId, status: {}};
    updates.status = action.data.status;        //update with the value from the action
    updates.updatedOn = action.data.updatedOn ?? Date.now();
    updates.isSubmitted = !!action.data.isSubmitted;
    const newForms = helpers.immAddOrReplace(state.forms, updates, f => f.id === action.documentId);

    let newState        = {
        ...state, 
        forms: newForms,
    };

    return newState;
}

function rowAdded(state, action){
    let rows    = state[action.sectionId] ?? [];
    if(!_.isArray(rows)) return state;
    rows    = [...rows, null];

    const result    = {
        ...state,
        a_lastModified  : helpers.getCurrentDateTime(),
        isDirty         : true,
        [action.sectionId]  : rows
    };

    return result;
}
    

function rowDeleted(state, action){
    let rows    = state[action.sectionId];
    if(rows && _.isArray(rows) && rows.length >= action.rowIndex){
        rows    = helpers.immRemoveItemByIndex(rows, action.rowIndex);
    }

    const result    = {
        ...state,
        a_lastModified  : helpers.getCurrentDateTime(), 
        isDirty         : true,
        [action.sectionId]  : rows
    };

    return result;
}

//-----
// Handles the loading of the data from the local store.
function onLocalLoaded(state, action){
    const isEmpty   = action.data === null;
    const data      = action.data ? _.omit(action.data, ["a_isLoaded", "a_lastModified", "local", "database", "meta"]) : {};
    const values    = data.values || data;  //backward-compatibility, for previous format of stored data

    return {
        ...state,
        a_isLoaded      : true,
        isDirty         : false,
        local   : {
            isLoaded    : true,
            error       : null,
            updatedAt   : helpers.getCurrentDateTime(),
            isEmpty     : isEmpty,
        },
        ...values,
    };
}

//----
// Handles the saving of data to the local store
function onLocalSaved(state, action){
    return {
        ...state,
        isDirty         : false,
        local       : {
            ...state.local,
            savedAt     : helpers.getCurrentDateTime(),
            isEmpty     : false,
        },
    };
}

function onCleared(state, action){
    return {
        ...INITIAL_STATE,
        a_isLoaded  : true,
    };
}

//----
// Handles the saving of data to the remote server
function onDbWorking(state, action){
    return {
        ...state,
        database    : {
            ...state.database,
            isWorking   : true,
            error       : null,
        },
    };
}

//----
// Handles the loading and saving of data to the remote server
function onDbLoadedOrSaved(state, action){
    const isOk      = Boolean(action.status === "ok");
    let data      = isOk ? action.data.values : null;
    //need to enumerate the data and compact all the array values to remove nulls
    data    = _.mapValues(data, v => isArray(v) ? _.compact(v) : v);
    // const forms     = isOk ? action.data.forms : null;      //this includes the region status for each form
    const reviewers = isOk ? action.data.reviewers : null;
    const ts    = helpers.getCurrentDateTime();
    const isSave= Boolean(action.type === VALUE_ACTIONS.DATA_DB_SAVED);
    const forms = isSave ? state.forms : action.data.forms;

    return {
        ...state,
        a_isLoaded : true,
        isDirty : false,
        a_lastModified : isSave ? null : state.a_lastModified,
        reviewers,
        forms: forms ?? {},
        database : {
            ...state.database,
            isWorking   : false,
            isLoaded    : isOk,
            error       : isOk ? null : action.error,
            updatedAt   : ts,
            savedAt     : isSave ? ts : null,
        },
        // meta        : meta,
        ...data,
    };
}

//----
// Handles the loading and saving of data to the remote server
function onLoadFailed(state, action){
    const error     = action.error;
    if(error.code === "permission-denied" || error.code === "not-found"){
        //This just means the data doesn't exist, in this scenario
        return {
            ...state,
            a_isLoaded : true,
            isDirty : false,
            a_lastModified : null,
            reviewers: null,
            forms: [],
            database : {
                ...state.database,
                isWorking   : false,
                isLoaded    : true,
                error       : null,
                updatedAt   : helpers.getCurrentDateTime(),
                savedAt     : null,
            },
        };
    }
    else{
        return {
            ...state,
            database    : {
                ...state.database,
                isWorking   : false,
                error       : error,                
            },
        };
    }    
}

function onDbCleared(state, action){
    const isOk  = Boolean(action.status === "ok");
    return {
        ...state,
        database    : {
            isWorking   : false,
            isLoaded    : isOk ? false : state.database.isLoaded,
            error       : isOk ? null : action.error,
            updatedAt   : isOk ? null : state.database.updatedAt,
            savedAt     : isOk ? null : state.database.savedAt,
        },
        //leave the data, we're not deleting it locally        
    };
}

//#region Adding a Reviewer

function addingReviewer(state, action){
    return {
        ...state,
        ...workingStatus("reviewerStatus"),
    };
}
//----
// Adds a reviewer who has access to this users values
function addReviewer(state, action){
    const newValue  = state.reviewers ? [action.id, ...state.reviewers] : [action.id];
    return {
        ...state,
        isDirty         : true,
        a_lastModified  : helpers.getCurrentDateTime(),
        reviewers       : newValue,
        ...finishedStatus("reviewerStatus", newValue),
    };
}

function addReviewerFailed(state, action){
    return {
        ...state,
        ...failedStatus("reviewerStatus", action.error),
    };
}
//#endregion

//#region Removing a Reviewer

function removingReviewer(state, action){
    return {
        ...state,
        ...workingStatus("reviewerStatus"),
    };
}
//----
// Adds a reviewer who has access to this users values
function removeReviewer(state, action){
    return {
        ...state,
        isDirty         : true,
        a_lastModified  : helpers.getCurrentDateTime(),
        reviewers       : helpers.immRemoveItem(state.reviewers, action.id),
    };
}

function removeReviewerFailed(state, action){
    return {
        ...state,
        ...failedStatus("reviewerStatus", action.error),
    };
}

//#endregion

function onSignedOut(state, action){
    return {...INITIAL_STATE };
}

const valuesReducer     = {
    [VALUE_ACTIONS.FIELD_UPDATED]    : itemUpdated,
    [VALUE_ACTIONS.ROW_ADDED]        : rowAdded,
    [VALUE_ACTIONS.ROW_DELETED]      : rowDeleted,
    [VALUE_ACTIONS.REGION_STATUS_UPDATED]   : updateRegionStatus,

    [VALUE_ACTIONS.DATA_SAVED]       : onLocalSaved,
    [VALUE_ACTIONS.DATA_LOADED]      : onLocalLoaded,
    [VALUE_ACTIONS.DATA_CLEARED]     : onCleared,

    
    [VALUE_ACTIONS.DATA_DB_WORKING]  : onDbWorking,
    [VALUE_ACTIONS.DATA_DB_LOADED]   : onDbLoadedOrSaved,
    [VALUE_ACTIONS.DATA_DB_SAVED]    : onDbLoadedOrSaved,  //does the same thing...
    [VALUE_ACTIONS.DB_LOAD_FAILED]   : onLoadFailed,
    [VALUE_ACTIONS.DATA_DB_DELETED]  : onDbCleared,

    [VALUE_ACTIONS.REVIEWER_ADDING]  : addingReviewer,
    [VALUE_ACTIONS.ADD_REVIEWER]     : addReviewer,
    [VALUE_ACTIONS.REVIEWER_ADD_FAILED] : addReviewerFailed,
    [VALUE_ACTIONS.REVIEWER_REMOVING]   : removingReviewer,
    [VALUE_ACTIONS.REMOVE_REVIEWER]  : removeReviewer,
    [VALUE_ACTIONS.REVIEWER_REMOVE_FAILED]  : removeReviewerFailed,

    [APP_ACTIONS.SIGNED_OUT]    : onSignedOut,
};

export default helpers.createReducer(INITIAL_STATE, valuesReducer);

//========================
// HELPER FUNCTIONS
function updateField(newState, action){
    if(_.isNumber(action.index)){
        //Update an item in a list section
        let newItems        = newState[action.section] || [];
        const existing      = newItems[action.index];
        let newItem         = {...existing, [action.field] : action.value };
        //Determine if this is an empty value, which needs to be removed
        newItem             = _.omitBy(newItem, v => {return !_.isNumber(v) && !v});
        // newItems.splice(action.index, 1, newItem);
        if(_.isEmpty(newItem)){
            newItems        = helpers.immRemoveItem(newItems, existing);
        }
        else{
            newItems        = helpers.immAddOrReplace(newItems, newItem, existing);        
        }
        newState[action.section]    = newItems;
    }
    else{
        //just a simple field update
        newState[action.field]      = action.value;
    }

    return newState;
}

function updateFieldWithManualRows(newState, action){
    if(_.isNumber(action.index)){
        //Update an item in a list section
        let newItems        = newState[action.section] || [];
        const existing      = newItems[action.index];
        let newItem         = {...existing, [action.field] : action.value };
        //Determine if this is an empty value, which needs to be removed
        // newItem             = _.omitBy(newItem, v => {return !_.isNumber(v) && !v});
        newItems.splice(action.index, 1, newItem);
        // if(_.isEmpty(newItem)){
        //     newItems        = helpers.immRemoveItem(newItems, existing);
        // }
        // else{
        //     newItems        = helpers.immAddOrReplace(newItems, newItem, existing);        
        // }
        newState[action.section]    = newItems;
    }
    else{
        //just a simple field update
        newState[action.field]      = action.value;
    }

    return newState;
}

// function updateDetails(newState, action){
//     if(action.details){
//         //Update details for a field
//         const field     = action.field;
//         const details   = action.details;
//         let updated     = newState.a_details || [];
//         if(details.items == null || details.items.lenth === 0){ //Removing the details
//             updated     = helpers.immRemoveItem(updated, d => d.field === field);
//         }
//         else{
//             updated     = helpers.immAddOrReplace(updated, details, d => d.field === field);
//         }

//         return updated;
//     }
    
//     return newState.a_details;
// }

