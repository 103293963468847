import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import FormSwitchField from "./form-switch-field";
import { IFormFieldInputProps } from "./part-types";

const FormBooleanField = ({ field, initialValue, onChanged, dataProps, showLabel }: IFormFieldInputProps) => {
  const [val, setVal] = useState<boolean>(initialValue);

  //=== watch the initialValue. It may change if this is a sameAs field, or there's some other dependency
  useEffect(() => {
    setVal(initialValue);
  }, [initialValue]);

  if(field.type === "switch" || field.subtype === "switch" || field.subtype === "toggle"){
    return <FormSwitchField field={field} initialValue={initialValue} onChanged={onChanged} dataProps={dataProps} />;
  }

  //subLabel, isNegative aren't on current schema, as they're legacy
  const { id, subLabel = null, isNegative = false } = (field  as any);
  
  const cbSize = field.size === "md" ? "medium" : "small";
  const classNames = `field-${field.size ? field.size : "sm"} field-checkbox`;
  const cbLabel = showLabel === true ? field.label || subLabel || null : null;
  const negProp = isNegative ? { "data-isnegative": true } : null;
  const inpProps = { ...dataProps, ...negProp };

  const handleChange = (e: any) => {
    const nextVal = e.target.checked;
    setVal(nextVal);

    if(onChanged && nextVal !== initialValue){
      onChanged(nextVal);
    }
  }

  return (
    <FormControlLabel 
      label={cbLabel} 
      className="field-checkbox-label" 
      control={
        <Checkbox 
          id={id} 
          size={cbSize} 
          className={classNames} 
          checked={val} 
          onChange={handleChange}
          inputProps={inpProps} 
        />
      } 
    />
  );
}

export default FormBooleanField; 
