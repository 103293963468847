import { Box, Button, ButtonProps, Menu } from "@mui/material";
import { useAnchor } from "helpers/hooks";
import { ReactElement, ReactNode } from "react";

//TODO: Need to test this out, haven't used it yet.

export interface IMenuButtonProps extends ButtonProps {
  label: string;
  anchorPosition?: { horizontal: "left" | "center" | "right", vertical: "top" | "center" | "bottom" }
  icon?: ReactElement;
  children: ReactNode;
}

const MenuButton = ({ label, icon, anchorPosition = { horizontal: "left", vertical: "bottom" }, children, ...buttonProps }: IMenuButtonProps) => {
  const [anchorEl, isOpen, onOpen, onClose] = useAnchor(anchorPosition);
  
  return (
    <Box sx={{ position: "relative" }}>
      <Button onClick={onOpen} endIcon={icon} size="small" variant="text" {...buttonProps}>
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={anchorPosition}
        onClick={() => { onClose(); }}
      >
        {children}
      </Menu>
    </Box>
  )

};

export default MenuButton;