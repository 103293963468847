import _, { isEmpty } from 'lodash';
import { trackEvent, Events } from 'helpers/analytics';
import { SHARE_CODES } from 'config/share-config';
import { startStatus, finishStatus } from 'redux-action-status';
import { StatusKeys } from 'helpers/status-keys';
import { APP_ACTIONS } from './action-types';
import { ApiUrls } from '../api-helpers';
import { loadProviderInfo } from './petitioner-actions';
import { removeUndefinedProps } from 'helpers/model-helpers';
import { getFirestoreId } from 'config/firebase-config';

const REQUESTS_LOADED = "INVITES_REQUESTS_LOADED";
const REQUEST_CREATED = "INVITE_REQUEST_CREATED";
const REQUEST_DELETED = "INVITE_REQUEST_DELETED";
const REQUEST_UPDATED = "INVITE_REQUEST_UPDATED";
const RECEIVED_LOADED = "INVITES_RECEIVED_LOADED";
const RECEIVED_UPDATED= "INVITE_RECEIVED_UPDATED";
const SHARE_SELECTED = "SHARE_SELECTED";
const SHARES_LOADED = "SHARES_LOADED", SHARE_LOADED = "SHARE_LOADED", SHARE_CREATED = "SHARE_CREATED", SHARE_DELETED = "SHARE_DELETED", SHARE_UPDATED = "SHARE_UPDATED";
const SHARE_PROFILE_LOADED = "SHARE_PROFILE_LOADED";
const REMOVE_SHAREDWITH = "REMOVE_SHAREDWITH";
const FORMCONFIG_LOADED = "FORMCONFIG_LOADED";
const DOC_UPLOADED = "DOC_UPLOADED", DOC_UPDATED = "DOC_UPDATED", DOC_DELETED = "DOC_DELETED";
const FILE_STORED = "FILE_STORED";

const INVITE_ACCEPTED = "INVITE_ACCEPTED";

export const SHARE_ACTIONS  = {
  RECEIVED_LOADED,  
  REQUESTS_LOADED, REQUEST_CREATED, REQUEST_DELETED, REQUEST_UPDATED, RECEIVED_UPDATED,
  SHARES_LOADED, SHARE_SELECTED, SHARE_LOADED, SHARE_CREATED, SHARE_DELETED, SHARE_UPDATED,
  SHARE_PROFILE_LOADED,
  REMOVE_SHAREDWITH,
  FORMCONFIG_LOADED,
  DOC_UPLOADED, DOC_UPDATED, DOC_DELETED,
  FILE_STORED,
  INVITE_ACCEPTED,
};

const INVITE_FIELDS   = ["firstName", "lastName", "email", "message", "requestType"];

export const initializeSharing = () => async(dispatch, getState) => {

  const profile   = getState().app.profile;
  if(!profile) return null;

  //Currently only supporting share requests from Attorney to Client, so only load the correct ones
  const isReviewer  = profile.role === "reviewer";
  
  await dispatch(startStatus(StatusKeys.shares));

  const p1  = isReviewer ? dispatch(loadSentRequests()) : Promise.resolve();
  const p2  = !isReviewer ? dispatch(loadReceivedRequests()) : Promise.resolve();
  const p3  = dispatch(loadShares());
  const results   = await Promise.all([p1, p2, p3]);
  
  await dispatch(finishStatus(StatusKeys.shares));

  return {
    status  : "ok",
    isOk    : true,
    data    : results,
  };
}

//---------------
// Load a user's sent invitations
export const loadSentRequests = (forceReload = false) => async(dispatch, getState) => {
  
  const profile  = getState().app.profile;
  
  //If this is an attorney, load them differently
  if(profile.accountId) return dispatch(loadAccountShareRequest);

  const result  = await dispatch({
    type      : REQUESTS_LOADED,
    firebase  : {
      type        : "getList",
      collection  : "shareRequests",
      query       : ["senderId", "==", profile.uid],
      // order       : ["invitedAt", "desc"],
      hydrate     : true,
    },
    ...(forceReload ? { statusKey: StatusKeys.shares } : {}),
  });

  return result;
}

//---------------
// Load a user's received invitations
export const loadReceivedRequests = (filter) => async(dispatch, getState) => {
  //Make sure we're not already loading the invites...
  const state  = getState();

  const result  = await dispatch({
    type      : RECEIVED_LOADED,
    firebase  : {
      type        : "getList",
      collection  : "shareRequests",
      query       : ["email", "==", state.app.profile.email.toLowerCase()],
      hydrate     : true,
    },
    // statusKey : "received",
  });

  //Now, grab the shares for any of the pending requests (the accepted ones will be retrieve in loadShares)
  // also grab the account info for the share requests
  if(result.isOk){
    const p1 = dispatch(loadPendingShares(result.data));
    const p2 = dispatch(loadRequestProviders(result.data));
    await Promise.all([p1, p2]);
  }

  return result;
}

const loadPendingShares = (shareRequests) => async(dispatch, getState) => {
  const requests = shareRequests ?? [];
  const pendingRequests = requests.filter(r => !!r.shareId && !r.acceptedAt);
  const shareIds = pendingRequests.map(r => r.shareId);
  
  if(shareIds.length > 0){
    const query = ["__id__", "in", shareIds];
    return await dispatch({
      type      : SHARES_LOADED,
      firebase  : {
        type        : "getList",
        collection  : "shares",
        query       : query,
        //don't get subcollections, since these haven't been accepted
        hydrate     : true,
      },
      // statusKey : StatusKeys.shares,
    });    
  }
  
  return Promise.resolve();
};

const loadRequestProviders = (shareRequests) => async(dispatch, getState) => {
  const requests = shareRequests ?? [];
  const pendingRequests = requests.filter(r => !!r.shareId && !r.acceptedAt);
  const providerIds = pendingRequests.map(r => r.accountId);

  if(providerIds.length > 0){
    return await Promise.all(providerIds.map(id => dispatch(loadProviderInfo(id))));
  }

  return Promise.resolve();
};

//---------------------
//#region Shares (CRD)
export const loadShares = (forceReload = false) => async(dispatch, getState) => {  
  const profile   = getState().app.profile;

  //for an account, we get shares via the api right now.
  if(profile.accountId) return dispatch(loadAccountShares);

  const query     = (profile.role === "user") ? ["sharer", "==", profile.uid] : ["reviewer", "==", profile.uid];

  const result  = await dispatch({
    type      : SHARES_LOADED,
    firebase  : {
      type        : "getList",
      collection  : "shares",
      query       : query,
      subCollections: ["forms", "dates", "documents"],
      hydrate     : true,
    },
    isReload: forceReload,
    ...(forceReload ? { statusKey: StatusKeys.shares } : {}),
  });

  return result;
};

const loadAccountShares = async(dispatch, getState) => {
  const accountId = getState().app.profile.accountId;
  if(!accountId) throw new Error("profile does not have an account, cannot load account shares");

  const url = `${ApiUrls.account}/${accountId}/shares`;
  const result = await dispatch({
    type: SHARES_LOADED,
    fetch: {
      url,
      verb: "GET",
      token: true,
    },
    accountId,
    // statusKey: StatusKeys.shares,
  });

  return result;
};

const loadAccountShareRequest = async (dispatch, getState) => {
  const accountId = getState().app.profile.accountId;
  if(!accountId) throw new Error("profile does not have an account, cannot load account share requests");

  const url = `${ApiUrls.account}/${accountId}/clients/invitations`;
  const result = await dispatch({
    type: REQUESTS_LOADED,
    fetch: {
      url,
      verb: "GET",
      token: true,
    },
    accountId,
    // statusKey: StatusKeys.shares,
  });

  return result;
};

export const refreshShare = (shareId, force = false) => async(dispatch, getState) => {
  const state = getState();
  const share = state.share.shares.find(s => s.id === shareId);
  if(!share) return null;

  const result = await dispatch({
    type: SHARE_LOADED,
    firebase: {
      type: "getSingle",
      collection: "shares",
      key: shareId,
      hydrate: true,
    },
    shareId,
    statusKey: StatusKeys.shares,
  });

  return result;

}

//#endregion

//---------------
//#region Share Documents

export const uploadDocument = (shareId, docProps) => async(dispatch, getState) => {
  const state = getState();
  const uid = state.app.profile.uid;
  const isAttorney = state.app.profile.role === "reviewer";
  const share = state.share.shares.find(s => s.id === shareId);
  const statusKey = isAttorney ? StatusKeys.clientDocuments : StatusKeys.petitioner;

  if(!share){
    console.error("Failed to add document", share.error);
    throw new Error("Failed to add document");
  }

  const { file, ...props} = docProps;
  if(!file) throw new Error("No file provided");

  const path = `shares/${share.id}/documents`;
  const now = Date.now();
  const ext = file.name.split(".").pop() ?? "pdf";
  
  //First, upload the file to storage so the sync will be able to get it
  const fileId = getFirestoreId(path);  //generate an id since we'll create it in storage first
  const docFileName = `${fileId}.${ext}`; //the storage file name will be the id of the document
  const filePath = `${path}/${docFileName}`;
  const fileResult = await dispatch({
    type: FILE_STORED,
    firebase: {
      type: "uploadFile",
      path: filePath,
      value: file,
    },
    statusKey,
  });

  if(!fileResult.isOk) {
    throw new Error("Failed to upload file");
  }

  //Next, add it to Firestore DB, to the /shares/{shareId}/documents collection
  let model = {
    ...props,
    ...(props.dueAt ? { dueAt: props.dueAt } : {}),
    direction: isAttorney ? "toClient" : "fromClient",
    filePath: path,
    fileName: docFileName,
    fileExtension: ext,
    originalFileName: file.name,
    createdBy: uid,
    createdAt: now,
    modifiedBy: uid,
    modifiedAt: now,
    version: 1,
    versionChanges: "original",
  };

  model = removeUndefinedProps(model);

  const result = await dispatch({
    type: DOC_UPLOADED,
    firebase: {
      type: "create",
      collection: path,
      value: model,
      key: fileId,
    },
    shareId: share.id,
    statusKey,
  });

  console.log("document added", result);
  if(!result.isOk) {
    throw new Error("Failed to add document");
  }

  // trackEvent(Events.form_created);
  return result;
};

export const uploadRequestedDocument = (shareId, requestDoc, docProps) => async(dispatch, getState) => {
  const state = getState();
  const uid = state.app.profile.uid;
  const share = state.share.shares.find(s => s.id === shareId);
  
  if(!share){
    console.error("Failed to add document", share.error);
    throw new Error("Failed to add document");
  }

  const { file, ...props} = docProps;
  if(!file) throw new Error("No file provided");

  const path = `shares/${share.id}/documents`;
  const now = Date.now();
  const ext = file.name.split(".").pop() ?? "pdf";
  
  //First, add a firestore doc to the /shares/{shareId}/documents collection
  const model = {
    ...props,
    filePath: path,
    originalFileName: file.name,
    fileExtension: ext,
    createdBy: uid,
    createdAt: now,
    modifiedBy: uid,
    modifiedAt: now,
    version: 1,
    versionChanges: "original",
  };

  const result = await dispatch({
    type: DOC_UPLOADED,
    firebase: {
      type: "updateSingle",
      collection: path,
      key: requestDoc.id,
      value: model,
    },
    shareId: share.id,
    statusKey: StatusKeys.petitioner,
  });

  console.log("document added", result);
  if(!result.isOk) {
    throw new Error("Failed to add document");
  }

  //get the file extension from the filename
  // const ext = file.name.split(".").pop();
  const docFileName = `${result.data.id}.${ext}`; //the storage file name will be the id of the document
  const filePath = `${path}/${docFileName}`;
  const fileResult = await dispatch({
    type: APP_ACTIONS.NO_OP,
    firebase: {
      type: "uploadFile",
      path: filePath,
      value: file,
    },
    statusKey: StatusKeys.petitioner,
  });

  if(!fileResult.isOk) {
    throw new Error("Failed to upload file");
  }

  // trackEvent(Events.form_created);
  return result;
};

export const updateDocument = (shareId, docId, changes) => async(dispatch, getState) => {
  const state = getState();
  const uid = state.app.profile.uid;
  const isAttorney = state.app.profile.role === "reviewer";
  const share = state.share.shares.find(s => s.id === shareId);
  const statusKey = isAttorney ? StatusKeys.clientDocuments : StatusKeys.petitioner;

  if(!share){
    console.error("Failed to update document, share not found.");
    throw new Error("Failed to update document");
  }

  const timestamp = Date.now();
  const { name, dueAt, isSharedWithClient, description } = changes;
  const updates = {
    ...(name ? { name } : {}),
    ...(description ? { description } : {}),
    ...(dueAt !== undefined ? { dueAt } : {}),    //need to account for null here
    ...(isSharedWithClient !== undefined ? { isSharedWithClient } : {}),
    modifiedBy: uid,
    modifiedAt: timestamp,
  };

  return dispatch({
    type: DOC_UPDATED,
    firebase: {
      type: "updateSingle",
      collection: `shares/${share.id}/documents`,
      key: docId,
      value: updates,
    },
    shareId: share.id,
    docId: docId,
    statusKey,  
  });
};

// export type TouchType = "viewed" | "downloaded";
export const touchedDocument = (shareId, docId, isViewed = true, isDownloaded = false) => async (dispatch, getState) => {
  const uid = getState().app.profile.uid;
  
  const modelUpdates = {};
  if(isViewed || isDownloaded) modelUpdates.viewed = { [uid]: Date.now() };
  if(isDownloaded) modelUpdates.downloaded = { [uid]: Date.now() };
  if(isEmpty(modelUpdates)) return;

  return dispatch({
    type: DOC_UPDATED,
    firebase: {
      type: "setSingle",
      collection: `shares/${shareId}/documents`,
      key: docId,
      value: modelUpdates,
    },
    shareId,
    docId,
    // statusKey: StatusKeys.clientDocuments,  
  });
};

export const deleteDocument = (shareId, docId) => async(dispatch, getState) => {
  const state = getState();
  const myUid = state.app.profile.uid;
  const share = state.share.shares.find(s => s.id === shareId);
  if(!share){
    console.error("Failed to delete document, share not found.");
    throw new Error("Failed to delete document");
  }

  const doc = share.documents.find(d => d.id === docId);
  if(!doc) throw new Error("Document not found in share");
  if(doc.createdBy !== myUid) throw new Error("You cannot delete a document you did not create");
  const statusKey = state.app.profile.role === "reviewer" ? StatusKeys.clientDocuments : StatusKeys.petitioner;

  const result = await dispatch({
    type: DOC_DELETED,
    firebase: {
      type: "deleteSingle",
      collection: `shares/${share.id}/documents`,
      subCollections: ["versions"],
      key: docId,
    },
    shareId: share.id,
    docId,
    statusKey,
  });

  return result;
};

//#endregion

//#region Invitations
//---------------

// Invite a user to the application
export const sendInvite = (model) => async(dispatch, getState) => {
  
  trackEvent(Events.invite);  //Track this in analytics

  const state   = getState();
  const profile = state.app.profile;
  const invite = _.pick(model, INVITE_FIELDS);
  if(invite.email) invite.email = invite.email.toLowerCase();

  const safeModel   = {
    ...invite,
    senderName    : profile.displayName,
    senderEmail   : profile.email.toLowerCase(),
    senderId      : profile.uid,
    senderType    : profile.role,
    invitedAt     : Date.now(), //getNowString(),
    invitationId  : null,
    sentAt        : null,
  };

  const result  = await dispatch({
    type    : REQUEST_CREATED,
    firebase: {
      type        : "create",
      collection  : "shareRequests",
      value       : safeModel,
    },
    statusKey : "requests",
  });

  //TODO: create an invitation for this share, so the user can log in...
  //TODO: Send the email about the invitation, then update the DB with the details
  
  return result;
}

// As a user, accept a provider's invitation, and share my data with them.
export const acceptInviteApi = (invite) => async(dispatch, getState) => {
  const url = `${ApiUrls.invitations}/${invite.id}/accept`;
  
  const result = await dispatch({
    type: INVITE_ACCEPTED,
    fetch: {
      url,
      verb: "PUT",
      token: true,
    },
    statusKey: StatusKeys.shares,
  });

  console.log("invite accepted", result);
  return result;
}

// export const acceptInvite = (invite) => async(dispatch, getState) => {
//   //+++++
//   //NOTE: must also add a reviewer to the Values structure.  See addReviewer action in values-reducer.js
//   //      that is not done here, so requires a sparate action from value-actions::addReviewer.
//   //+++++
//   const state     = await getState();
//   const profile   = state.app.profile;

//   //If the invite doesn't have a shareId, don't allow this right now
//   if(!invite.shareId){
//     console.error("Invite does not have a shareId.  Cannot accept.", invite);
//     return;
//   }

//   const sharedWith = {
//     sharedAt: Date.now(),
//     type: "reviewer",
//     shareId: invite.shareId,
//   };

//   //This is necessary for the firestore rules, to give the reviewer access to the sharer's profile
//   const sharedWithResult = await dispatch({
//     type: APP_ACTIONS.NO_OP,
//     firebase: {
//         type: "create",
//         collection: `profiles/${profile.email}/sharedWith`,
//         key: invite.senderId,
//         value: sharedWith,
//     },
//     //for the reducer
//     reviewerId: invite.senderId,        
//     statusKey: StatusKeys.shares,
//   });
  
//   if(!sharedWithResult.isOk){
//     console.error("failed to add sharedWith to profile", sharedWithResult);
//     return sharedWithResult;
//   }  

//   //Add a sharedWith to the values as well
//   const valueSharedWithResult = await dispatch({
//     type: APP_ACTIONS.NO_OP,
//     firebase: {
//       type: "create",
//       collection: `values/${profile.uid}/sharedWith`,
//       key: invite.senderId,
//       value: sharedWith,
//     },
//     reviewerId: invite.senderId,
//     statusKey: StatusKeys.shares,
//   });

//   if(!valueSharedWithResult.isOk){
//     console.error("failed to add sharedWith to values", valueSharedWithResult);
//     return valueSharedWithResult;
//   }

//   //Add the reviewer to the sharer's values
//   // const valueResult = await dispatch(addReviewer(invite.senderId));
//   let reviewers = state.values.reviewers ?? [];
//   if(!reviewers.includes(invite.senderId)){
//     reviewers = [...reviewers, invite.senderId];
//   }
//   const reviewerAdded = await dispatch({
//     type: APP_ACTIONS.NO_OP,
//     firebase: {
//       type: "updateSingle",
//       collection: "values",
//       key: profile.uid,
//       value: { reviewers },
//     },
//     statusKey: StatusKeys.shares,
//   });
//   if(!reviewerAdded.isOk){
//     console.error("failed to add reviewer to values", reviewerAdded);
//     return reviewerAdded;
//   }

//   //update the share with the sharer
//   const shareUpdates = {
//     sharer: profile.uid,
//     sharedAt: Date.now(),
//   };
//   const shareResult  = await dispatch({
//     type      : APP_ACTIONS.NO_OP,  //This is no-op because we reload all shares below
//     firebase  : {
//       type        : "updateSingle",
//       collection  : "shares",
//       key: invite.shareId,
//       value       : shareUpdates,
//     },
//     statusKey   : StatusKeys.shares,
//   });

//   if(shareResult.isOk){

//     //reload the shares
//     await dispatch(loadShares(true)); //reload the shares

//     //Now, need to update the invitation
//     const changes   = {
//       status: SHARE_CODES.accepted,
//       acceptedAt: Date.now(), 
//       modifiedAt: Date.now(),
//     };
  
//     await dispatch({
//       type      : RECEIVED_UPDATED,
//       firebase  : {
//         type        : "updateSingle",
//         collection  : "shareRequests",
//         key         : invite.id,
//         value       : changes,
//       },
//       statusKey   : StatusKeys.shares,
//     });

//   }

//   return shareResult;

// }

//TODO: NEXT - change this to use the new firebase cloud function for rejecting

export const rejectInvite = (inviteId, shareId) => async(dispatch, getState) => {
  //+++++
  //NOTE: may also need to remove a reviewer from the Values structure.  See removeReviewer action in values-reducer.js
  //+++++
  let shareResult   = {};
  if(shareId){
    
    shareResult  = await dispatch({
      type      : SHARE_DELETED,
      firebase  : {
        type        : "deleteSingle",
        collection  : "shares",
        key         : shareId,
      },
      id   : shareId,
      statusKey   : "shares",
    });
  }

  if(!shareId || shareResult.isOk){
    //Now, need to update the invitation
    const changes   = {status: SHARE_CODES.rejected, modifiedAt: Date.now()}; //getNowString()};
  
    await dispatch({
      type      : RECEIVED_UPDATED,
      firebase  : {
        type        : "updateSingle",
        collection  : "shareRequests",
        key         : inviteId,
        value       : changes,
      },
      id        : inviteId,
      // statusKey : "received",
    });
  }

  return shareResult;

}

//---------------
// Update an invitation
export const updateReceivedInvite = (id, changes) => async(dispatch, getState) => {
  //_.pick to whitelist the changes that are allowed
  const myChanges   = _.pick(changes, ["viewedAt", "status", "modifiedAt"]);
  
  const result  = await dispatch({
    type      : RECEIVED_UPDATED,
    firebase  : {
      type        : "updateSingle",
      collection  : "shareRequests",
      key         : id,
      value       : myChanges,
    },
    id        : id,
    // statusKey : "received",
  });

  return result;
}

//---------------
// Delete a sent invitation (request).  When someone wants to withdraw an
// invitation they've sent
export const deleteSentInvite = (id) => async(dispatch, getState) => {
  
  const result  = await dispatch({
    type      : REQUEST_DELETED,
    firebase  : {
      type        : "deleteSingle",
      collection  : "shareRequests",
      key         : id,
    },
    id        : id,
    statusKey : "requests",
  });

  return result;
}
//#endregion

export const downloadFormConfig = (form) => async (dispatch, getState) => {
  const state = getState();
  if(!state.app.isInitialized) return;

  //Make sure we don't already have this config
  const existingConfigs = state.share.formCongifs ?? {};
  if(existingConfigs[form.formId]) return { isOk: true, data: existingConfigs[form.formId] };

  //Otherwise, need to download it
  const formId = form.formId ?? form.id;
  const filePath = `${form.filePath}/${formId}/${formId}.config.json`;
  console.info("Form Configuration File Path", filePath);
  
  const result = await dispatch({
    type: FORMCONFIG_LOADED,
    firebase: {
      type: "downloadFile",
      downloadType: "json",
      path: filePath,
    },
    //For the reducer
    shareId: form.shareId,
    formId: form.formId,
    statusKey: StatusKeys.formContext,
  });

  return result;
};

export const downloadDocument = (doc, versionNumber = 0) => async(dispatch, getState) => {
  const state = getState();
  if(!state.app.isInitialized) return;

  const fileExtension = doc.originalFileName.split('.').pop();
  let fileName = doc.fileName ?? `${doc.id}.${fileExtension}`;
  let downloadFileName = doc.originalFileName;

  if(versionNumber > 0){
    const ver = doc.versions.find(v => v.version === versionNumber);
    if(ver){
      fileName = ver.fileName;
      const fileNameWithoutExtension = doc.originalFileName.split('.').slice(0, -1).join('.');
      downloadFileName = `${fileNameWithoutExtension}_VERSION_${versionNumber}.${fileExtension}`;
    }
  }

  const filePath = `${doc.filePath}/${fileName}`;

  const result = await dispatch({
    type: APP_ACTIONS.NO_OP,
    firebase: {
      type: "downloadFile",
      downloadType: "blob",
      downloadFileName: downloadFileName,
      path: filePath,
    },
    //For the reducer
    statusKey: StatusKeys.clientDocuments,
  });

  return result;
};

// Will download the file from storage, and return the blob without opening or creating the file
export const downloadBlob = (doc, versionNumber = 0) => async(dispatch, getState) => {
  const state = getState();
  if(!state.app.isInitialized) return;

  const fileExtension = doc.originalFileName.split('.').pop();
  let fileName = doc.fileName ?? `${doc.id}.${fileExtension}`;
  // let downloadFileName = doc.originalFileName;

  if(versionNumber > 0){
    const ver = doc.versions.find(v => v.version === versionNumber);
    if(ver){
      fileName = ver.fileName;
      // const fileNameWithoutExtension = doc.originalFileName.split('.').slice(0, -1).join('.');
      // downloadFileName = `${fileNameWithoutExtension}_VERSION_${versionNumber}.${fileExtension}`;
    }
  }

  const filePath = `${doc.filePath}/${fileName}`;

  const result = await dispatch({
    type: APP_ACTIONS.NO_OP,
    firebase: {
      type: "downloadFile",
      downloadType: "blob",
      // downloadFileName: downloadFileName,
      path: filePath,
    },
    //For the reducer
    statusKey: StatusKeys.clientDocuments,
  });

  return result;
};