import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { isString } from "lodash";
import { Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { AccountCircle as AccountCircleIcon, EmailOutlined as EmailIcon, PhoneOutlined as PhoneIcon, EditOutlined as EditIcon, PersonAddOutlined as InviteIcon, ArrowBack, RefreshOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { iconStyles, textStyles } from "helpers/style-helpers";
import { useClient } from "../client-context";
import { useShareContext } from "sections/app/share-context";
import ClientStatusChip from "./chips/client-status-chip";
import { ShareStatus } from "types";
import { useInterval } from "helpers/hooks";
import { refreshShare } from "store/actions/share-actions";
import { REFRESH_INTERVAL } from "helpers/model-helpers";
import { useAttorneyContext } from "../attorney-context";
import { assignClient } from "store/actions/attorney-actions";
import { ClientAssignmentChip } from "./chips";
import InviteClientDialog from "../invite-client-dialog";
import ClientSyncChip from "./provider-accounts/parts/client-sync-chip";

const ClientSummaryPanel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { members } = useAttorneyContext();
  const { currentClient: clientProfile, refreshClient, inviteClient, closeClient, isNew, isEdit, clientId } = useClient();
  const { share, shareStatus } = useShareContext();
  const [isCopyInvite, setIsCopyInvite] = useState(false);

  //display name based on the client information we have
  const displayName = useMemo(() => {
    if(isNew && !clientProfile) return "New Client";
    else if(!clientProfile) return "Unknown Client";
    else return `${clientProfile.firstName} ${clientProfile.lastName}`;
  }, [clientProfile, isNew]);

  useInterval(() => {
    if(share?.id && shareStatus === ShareStatus.INVITED) dispatch(refreshShare(share?.id));
  }, [REFRESH_INTERVAL]);

  const handleEdit = () => {
    console.log("Edit client profile", pathname);
    const nextPath = `/app/clients/edit/${clientId}`; // pathname.replace("clients", "clients/edit");
    console.log("Edit client profile", nextPath);
    navigate(nextPath);
  };
  
  const handleAssign = async (e: any) => {
    let uid   = isString(e) ? e : e.target.value;
    if(uid === "") uid = null;
    const result = await dispatch(assignClient(clientId, uid));
    if((result as any).isOk){
      toast.success("Client assigned successfully");
    }
  };

  return (
    <Stack rowGap={3} pr={2} alignItems="center">
      <Stack alignItems="center">
        <AccountCircleIcon color="primary" {...iconStyles.avatarLarge }/>
        <Typography {...textStyles.title} textAlign="center">{displayName}</Typography>
        <ContactItem icon={<EmailIcon fontSize="small" />} label="Email" value={clientProfile?.email} />
        <ContactItem icon={<PhoneIcon fontSize="small" />} label="Phone" value={clientProfile?.phone} />
      </Stack>
      
      {!isNew && (
        <Stack alignItems="center" rowGap={3} width="100%" px={2}>
          <ClientStatusChip share={share} />
          <ClientSyncChip />
          <ClientAssignmentChip assignedTo={clientProfile.assignedTo} members={members} onChange={handleAssign} />
          <Divider variant="middle" sx={{ width: "80%" }}/>
        </Stack>
      )}

      <Stack gap={1}>
        {(!isNew && shareStatus === ShareStatus.NOT_INVITED) && <Button onClick={inviteClient} color="secondary" size="small" startIcon={<InviteIcon fontSize="small" />}>Send Invite</Button> }
        {shareStatus === ShareStatus.INVITED && <Button onClick={() => setIsCopyInvite(true)} color="secondary" size="small" startIcon={<InviteIcon fontSize="small" />}>Copy Invite</Button> }
        <Button onClick={closeClient} size="small" color="secondary" title="Close and return to Client List" startIcon={<ArrowBack fontSize="small" />} sx={{ fontSize: "medium" }}>Back</Button>
        {!isNew && <Button onClick={refreshClient} size="small" color="secondary" title="Refresh this client" startIcon={<RefreshOutlined fontSize="small" />} sx={{ fontSize: "medium" }}>Refresh</Button>}
        <Button onClick={handleEdit} size="small" color="secondary" title="Edit client profile" hidden={isEdit} startIcon={<EditIcon fontSize="small" />} sx={{ fontSize: "medium" }}>Edit</Button>        
      </Stack>

      {isCopyInvite && <InviteClientDialog isOpen={true} isCopyOnly={true} clientProfile={clientProfile} onClose={() => setIsCopyInvite(false)} />}
    </Stack>
  );
};

export default ClientSummaryPanel;


const ContactItem = ({ icon, label, value }: any) => {

  if(!value) return null;

  const onCopy =  () => {
    navigator.clipboard.writeText(value);
    toast(`${label} copied to clipboard`, { type: "info", autoClose: 2000});
  }

  return (
    <Tooltip title={`${label}: Click to copy`} placement="top">
      <Grid container alignItems="center" onClick={onCopy}>
        <Typography {...textStyles.subTitle}>{value}</Typography>
        {/* <IconButton size="small" color="secondary" onClick={onCopy}>
          {icon}
        </IconButton> */}
      </Grid>
    </Tooltip>
  );
};