import _ from 'lodash';
import { VALUE_ACTIONS } from './action-types';
import { DOWNLOADED_KEY } from '../localstorage-keys';
import { downloadRaw, prepareValues, prepareValuesLocal } from './action-helpers';
import { StatusKeys } from 'helpers';

export function updateFieldValue(region, section, field, value, details = null, index = null, isManual = false){
    return {
        type    : VALUE_ACTIONS.FIELD_UPDATED,
        region  : region,
        section : section,
        field   : field,
        value   : value,
        details : details,
        index   : index,
        isManual,
    };
}

//#region Form Status

export const initializeFormStatus = (formId, regionIds) => (dispatch, getState) =>{
    const state = getState();
    if(!state.values.a_isLoaded) return null;   //existing data isn't loaded, so exit and don't overwrite

    const uid = state.app.user.uid;
    const collection = `values/${uid}/forms`;
    // const doc = formId;
    const formMeta = state.values.forms.find(f => f.id === formId);
    const existingStatus = formMeta?.status || {};    
    const initialStatus = regionIds.reduce((a, b) => ({...a, [b]: false}), {});

    //Check to see if the keys of the two objects are the same
    const existingKeys = Object.keys(existingStatus).sort();
    const initialKeys = Object.keys(initialStatus).sort();
    if(JSON.stringify(existingKeys) === JSON.stringify(initialKeys)) return null;
    
    const value = {
        ...formMeta,
        status: {
            ...initialStatus,
            ...existingStatus,
        },
    };

    //See if there are any changes
    console.debug("initializing document status", { documentId: formId, value });
    return dispatch({
        type: VALUE_ACTIONS.REGION_STATUS_UPDATED,
        firebase: {
            type: "setSingle",
            collection: collection,
            key: formId,
            value: value,
        },
        //for the reducer
        documentId: formId,
        statusKey: "shares",
      });
}

export const updateFormStatus = (formId, regionId, isComplete) => async (dispatch, getState) => {
    const uid = getState().app.user.uid;
    const collection = `values/${uid}/forms`;
    // const doc = formId;

    let value = { 
        updatedOn: Date.now(),
    };
    if(regionId) value.status = {[regionId]: isComplete};
    
    return dispatch({
        type: VALUE_ACTIONS.REGION_STATUS_UPDATED,
        firebase: {
            type: "setSingle",
            collection: collection,
            key: formId,
            value: value,
        },
        //for the reducer
        documentId: formId,
        statusKey: StatusKeys.shares,
      });
};

export const submitForm = (formId) => async (dispatch, getState) => {
    const uid = getState().app.user.uid;
    const collection = `values/${uid}/forms`;

    const value = { 
        updatedOn: Date.now(),
        submittedOn: Date.now(),
        isSubmitted: true,
    };
    
    return dispatch({
        type: VALUE_ACTIONS.REGION_STATUS_UPDATED,
        firebase: {
            type: "setSingle",
            collection: collection,
            key: formId,
            value: value,
        },
        //for the reducer
        documentId: formId,
        statusKey: StatusKeys.shares,
      });
};

//#endregion

export const addRow = (sectionId) => async(dispatch, getState) => {
    return dispatch({
        type    : VALUE_ACTIONS.ROW_ADDED,
        sectionId   : sectionId,
    });
};

//----
// Deletes a row from a list section
export const deleteRow = (sectionId, rowIndex) => async(dispatch, getState) => {
    return dispatch({
        type    : VALUE_ACTIONS.ROW_DELETED,
        sectionId   : sectionId,
        rowIndex    : rowIndex,
    });
};

export const saveDataLocal = (isForced) => async (dispatch, getState) => {
    //TODO: standardize the data structure, use the prepareValues method
    // to group the metadata, and save it in localstorage the same way
    
    //Determine if there's anything to save
    const state     = getState();
    const profile   = state.app.profile;
    const values    = state.values;
    const localProps = values.local.meta || values.local;

    if(!isForced && !values.isDirty) return null;   //no changes, so don't need to save
    if(!isForced && localProps.savedAt && localProps.a_lastModified && (new Date(localProps.savedAt) >= new Date(values.a_lastModified))) return null;    //no changes since last save

    const data      = prepareValuesLocal(state);

    return dispatch({
        type        : VALUE_ACTIONS.DATA_SAVED,
        localStorage    : {
            type    : "write",
            domain  : "values",
            key     : profile.uid,      //store it under the user's UID
            // tag     : {key: SAVED_KEY, value: (new Date()).toLocaleString()},
            value   : data,
        }
    });
}

export const loadDataLocal = (isSilent) => async(dispatch, getState) => {
    const state     = getState();
    const profile   = state.app.profile;

    return dispatch({
        type        : isSilent ? VALUE_ACTIONS.DATA_LOADED_SILENT : VALUE_ACTIONS.DATA_LOADED,
        localStorage    : {
            type    : "read",
            key     : profile.uid,      //store it under the user's UID
        }
    });
}

export const loadData = () => async (dispatch, getState) => {
    const state = getState();
    if(!state.app.isInitialized) return null;   //not ready yet...
    let result  = null;

    //Validate that we're storing the data remotely
    const profile   = state.app.profile;
    if(profile.role === "reviewer") return { isOk: true, data: null };
    // if(profile.isRemote === false){
    //     return await dispatch(loadDataLocal());
    // }
    // else{
        //Need to account for case where local data is newer than remote data
        // (in the case of a crash, or an issue syncing with the server)
        //Silently read the local storage data
        const local     = await dispatch(loadDataLocal(true));
        const remote    = await dispatch(loadDataFromServer(profile));
        if(!local.data) return remote;  //No local data, we're done here.

        const localTime     = new Date(local.data?.meta?.lastSaved);
        const remoteTime    = remote?.data ? new Date(remote.data.meta.savedAt) : new Date();    //if no remote data, then this is the first time

        //if local time is greater, need to use that one
        if(localTime && localTime > remoteTime){
            const localData     = {
                ..._.omit(local.data, ["meta", "local", "database"]),
            };

            await dispatch({
                type    : VALUE_ACTIONS.DATA_LOADED,
                data    : localData,
            });

            //Then save the data to the server...
            result  = await dispatch(saveDataToServer());
        }

        return result || remote;
    // }
}

//---
// clears all the data from localStorage and the values reducer
export const clearLocalData = () => async(dispatch, getState) => {
    const profile   = getState().app.profile;
    return await dispatch({
        type        : VALUE_ACTIONS.DATA_CLEARED,
        localStorage    : {
            key     : profile.uid,
            type    : "delete"
        }
    });
}

//----
// Removes the user's values data from the server database (doe not clear state or localstorage)
export const clearDataFromServer = (profile) => async(dispatch, getState) => {
    if(!profile){
        const state     = getState();        
        profile         = state.app.profile;    //Validate that we're storing the data remotely
    }

    await dispatch({type: VALUE_ACTIONS.DATA_DB_WORKING});

    const result    = await dispatch({
        type        : VALUE_ACTIONS.DATA_DB_DELETED,
        firebase    : {
            type        : "deleteSingle",
            collection  : "values",
            key         : profile.uid,  //Currently storing values under the UID
        },
    });

    return result;
}

//---
// exports the user's data as a json file
export const downloadRawData = () => async (dispatch, getState) => {
    let state   = getState();
    
    //Download it
    downloadRaw(state);

    //TODO: update the profile on the server, rather than write it locally??
    //Track when it was downloaded last
    await dispatch({
        type        : VALUE_ACTIONS.DATA_SAVED,
        localStorage    : {
            type    : "write_value",
            key     : DOWNLOADED_KEY,
            value   : (new Date()).toLocaleString(),
        }
    });

    //Trigger the fact that it was downloaded
    return {
        type    : VALUE_ACTIONS.DATA_DOWNLOADED,
    };
}

//---
// Loads the values data from json.  Used to import data after opening a downloaded json file.
export const loadRawData = (json) => async(dispatch, getState) => {
    //First, clear out the data both locally and remotely
    await dispatch(clearLocalData());
    await dispatch(clearDataFromServer());

    //Next, load the data from the file into the store
    await dispatch({
        type    : VALUE_ACTIONS.DATA_LOADED,
        data    : json.values,
    });

    //Finally, save it locally and remotely
    await dispatch(saveDataLocal(true));    //force it to save the local version of the data
    return await dispatch(saveDataToServer(true));
}

export const loadDataFromServer = (profile) => async(dispatch, getState) => {
    if(!profile){
        const state     = await getState();        
        profile         = state.app.profile;    //Validate that we're storing the data remotely
    }
    if(!profile.isRemote) return null;
    

    await dispatch({type: VALUE_ACTIONS.DATA_DB_WORKING});

    const result    = await dispatch({
        type        : VALUE_ACTIONS.DATA_DB_LOADED,
        failType    : VALUE_ACTIONS.DB_LOAD_FAILED,
        firebase    : {
            type        : "getSingle",
            collection  : "values",
            subCollections: ["forms"],
            key         : profile.uid,  //Currently storing values under the UID
        },
    });

    if(result.error?.code === "not-found"){
        const initResult = await initializeValues(profile.uid, getState(), dispatch);
        console.log("initialized user values", initResult);
        return initResult;
    }

    return result;
}

export const saveDataToServer = (isForced) => async(dispatch, getState) => {
    let state       = getState();
    const profile   = state.app.profile;
    if(!profile) return null;       //no profile, was already logged out

    const vState    = state.values;
    const dbProps   = vState.database;
    
    //Figure out if we even need to be here
    if(!isForced){
        if(!profile.isRemote) return null;      //user isn't remote, so no need to save
        if(!vState.a_lastModified) return null;   //no changes, so don't need to save
        if(dbProps.savedAt && (new Date(dbProps.savedAt) >= new Date(vState.a_lastModified))) return null;    //no changes since last save
    }
    //with the initialize functionality below, it should always be an update here.
    // const fbType    = dbProps.isLoaded ? "updateSingle" : "create";
    const data      = prepareValues(state);
    
    await dispatch({type: VALUE_ACTIONS.DATA_DB_WORKING});

    const result    = await dispatch({
        type        : VALUE_ACTIONS.DATA_DB_SAVED,
        firebase    : {
            type        : "updateSingle", //fbType,
            collection  : "values",
            key         : profile.uid,  //Currently storing values under the UID
            value       : data,
        },
    });

    console.info("data saved to server", result?.status);
    return result;
    
}

//------
// Adds a reviewer to the values collection
export const addReviewer = (reviewerUid) => async(dispatch, getState) => {
    const state     = await getState();
    const profile   = state.app.profile;
    if(!profile.isRemote) return null;

    const values    = state.values;
    if(!values.database.isLoaded){
        //Need to load the values from the DB
        const loadResult    = await dispatch(loadDataFromServer(profile));
        if(!loadResult.isOk){
            return dispatch({type: VALUE_ACTIONS.REVIEWER_ADD_FAILED});
        }
    }

    //add a reviewer
    await dispatch({type: VALUE_ACTIONS.ADD_REVIEWER, id: reviewerUid});
    
    //save the changes back to the server  
    const saveResult    = await dispatch(saveDataToServer());
    if(!saveResult.isOk){
        return dispatch({type: VALUE_ACTIONS.REVIEWER_ADD_FAILED});
    }

    //TODO: Notify the requestor that their share has been accepted?
    return saveResult;
}

//------
// Removes a reviewer from the values collection
export const removeReviewer = (reviewerUid) => async(dispatch, getState) => {
    const state     = await getState();
    const profile   = state.app.profile;
    if(!profile.isRemote) return null;

    const values    = state.values;
    if(!values.database.isLoaded){
        //Need to load the values from the DB
        const loadResult    = await dispatch(loadDataFromServer(profile));
        if(!loadResult.isOk){
            return dispatch({type: VALUE_ACTIONS.REVIEWER_REMOVE_FAILED});
        }
    }

    //remove the reviewer
    await dispatch({type: VALUE_ACTIONS.REMOVE_REVIEWER, id: reviewerUid});
    
    //save the changes back to the server  
    const saveResult    = await dispatch(saveDataToServer());
    if(!saveResult.isOk){
        return dispatch({type: VALUE_ACTIONS.REVIEWER_REMOVE_FAILED});
    }

    //TODO: Notify the recipient that they've been removed?
    return saveResult;
}

export const initializeValues = async (uid, state, dispatch) => {

    const initValues = prepareValues(state);
    await dispatch({type: VALUE_ACTIONS.DATA_DB_WORKING});

    const result    = await dispatch({
        type        : VALUE_ACTIONS.DATA_DB_SAVED,
        firebase    : {
            type        : "create",
            collection  : "values",
            key         : uid,  //Currently storing values under the UID
            value       : initValues,
        },
    });

    // console.log("initialize user values", result);
    return result;
}