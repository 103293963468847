import _, { isString } from 'lodash';
import { Events, trackEvent } from "helpers/analytics";
import { ClientDocument, ClientEditableDate, ClientForm, ClientProfile, ExportTemplate, FormConfiguration, FormFields, FormSchema, FormSummary, Share, TemplateOptions } from "types";
import { ClientDateSchema, DocumentSchema, ClientNoteSchema, ExportTemplateSchema } from 'types/schema';
import { ClientDocumentUpload } from 'types/client-document-types';
import { StatusKeys } from 'helpers/status-keys';
import { dateStringToNumber } from 'helpers/general-helpers';
import { REFRESH_INTERVAL, removeBlankArrayItems, removeUndefinedProps } from 'helpers/model-helpers';
import { getLocalTemplateAdapter, runValuesAdapter } from 'helpers/export-adapters/values-adapter';
import { AssignableForm } from 'sections/collaborator/parts/assign-form-dialog/assignable-form-types';
import { ApiUrls } from '../api-helpers';
import { API_URL, MERGE_URL, prepareForMerge } from './action-helpers';
import { APP_ACTIONS } from './action-types';
import { downloadFormConfig, uploadDocument } from './share-actions';
import { getFirestoreId } from 'config/firebase-config';
import { prepareSameAsFields } from './action-helpers-2';

const FORMS_LOADED = "FORMS_LOADED", FORMS_COMMON_LOADED = "FORMS_COMMON_LOADED";
const FORM_ADDED = "FORM_ADDED", FORM_ADD_ERROR = "FORM_ADD_ERROR";
const FORM_UPDATED = "FORM_UPDATED";
const FORMS_ASSIGNED = "FORMS_ASSIGNED", FORMS_UNASSIGNED = "FORMS_UNASSIGNED", FORM_ASSIGNMENT_UPDATED = "FORM_ASSIGNMENT_UPDATED";
const CLIENT_DOC_ADDED = "CLIENT_DOC_ADDED", CLIENT_DOC_UPLOADED = "CLIENT_DOC_UPLOADED", CLIENT_DOC_UPDATED = "CLIENT_DOC_UPDATED";
const CLIENT_LIST_LOADED = "CLIENT_LIST_LOADED", CLIENT_LOADED = "CLIENT_LOADED";
const CLIENT_CREATED = "CLIENT_CREATED", CLIENT_UPDATED = "CLIENT_UPDATED", CLIENT_INVITED = "CLIENT_INVITED";
const CLIENT_VALUES_LOADED = "CLIENT_VALUES_LOADED", CLIENT_VALUES_UPDATED = "CLIENT_VALUES_UPDATED";
const CLIENT_CHOSEN = "CLIENT_CHOSEN";
const CLIENT_DATE_ADDED = "CLIENT_DATE_ADDED", CLIENT_DATE_UPDATED = "CLIENT_DATE_UPDATED", CLIENT_DATE_DELETED = "CLIENT_DATE_DELETED";
const CLIENT_NOTES_LOADED = "CLIENT_NOTES_LOADED", CLIENT_NOTE_ADDED = "CLIENT_NOTE_ADDED", CLIENT_NOTE_UPDATED = "CLIENT_NOTE_UPDATED", CLIENT_NOTE_DELETED = "CLIENT_NOTE_DELETED";
const CREATE_ACCOUNT = "CREATE_ACCOUNT", ADD_ACCOUNT_MEMBER = "ADD_ACCOUNT_MEMBER";
const CLIENT_SYNCED = "CLIENT_SYNCED";
const TOGGLE_EDIT_CLIENT_DATA = "TOGGLE_EDIT_CLIENT_DATA";

export const ATTORNEY_ACTIONS = {
  FORMS_LOADED, FORMS_COMMON_LOADED,
  FORM_ADDED, FORM_ADD_ERROR, 
  FORM_UPDATED,
  FORMS_ASSIGNED, FORMS_UNASSIGNED, FORM_ASSIGNMENT_UPDATED,
  CLIENT_LIST_LOADED, CLIENT_CHOSEN, CLIENT_LOADED,
  CLIENT_VALUES_LOADED, CLIENT_VALUES_UPDATED,
  CLIENT_CREATED, CLIENT_UPDATED, CLIENT_INVITED,
  CLIENT_DATE_ADDED, CLIENT_DATE_UPDATED, CLIENT_DATE_DELETED,
  CLIENT_NOTES_LOADED, CLIENT_NOTE_ADDED, CLIENT_NOTE_UPDATED, CLIENT_NOTE_DELETED,
  CLIENT_DOC_ADDED, CLIENT_DOC_UPDATED, CLIENT_DOC_UPLOADED,
  CREATE_ACCOUNT, ADD_ACCOUNT_MEMBER,
  CLIENT_SYNCED,
  TOGGLE_EDIT_CLIENT_DATA,
};

const CLIENT_FIELD_WHITELIST = ["firstName", "lastName", "email", "phone", "address1", "address2", "city", "state", "zip", "summary", "areas", "isStarred", "cloudSync", "isCouple", "partnerFirstName", "partnerLastName"];
const accountsUrlBase = `${API_URL}/accounts`;
export type StateAbbreviations = "CO" | "CA" | "MA";

//#region Form Actions

export const loadAttorneyForms = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;
  
  if(!accountId) {
    return;
  }

  const result = await dispatch({
    type: FORMS_LOADED,
    fetch: {
      url: `${ApiUrls.account}/${accountId}/forms`,
      token: true,
    },
    //For the reducer
    accountId,
    statusKey: "forms",
    stateCode: "CO",  //TODO: this should be based on the account
  });
  
  return result;
};

export const addAttorneyForm = (form: any) => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;
  
  const { file, ...rest } = form;
  
  //first, add the info to the database
  let filePath = `${accountId}/forms`;
  const model = {
    ...rest,
    originalFileName: file.name,
    filePath,
  };
  
  const result = await dispatch({
    type: FORM_ADDED,
    fetch: {
      url: `${ApiUrls.account}/${accountId}/forms`,
      verb: "POST",
      data: model,
      token: true,
    },
    //For the reducer
    attorneyId: accountId,
    statusKey: "forms",
  });

  console.log("form added", result);

  //Now add the form to the database
  filePath += `/${result.data.id}/${file.name}`;
  const fileResult = await dispatch({
    type: "FILE_UPLOADED",
    failType: "FILE_UPLOAD_ERROR",
    firebase: {
      type: "uploadFile",
      path: filePath,
      value: file,
    }
  });

  if(!fileResult.isOk) {
    throw new Error("Failed to upload file");
  }

  trackEvent(Events.form_created);
  return result;
};

export const updateAttorneyForm = (formId: string, form: Partial<FormFields>) => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;
  
  const propWhitelist = ["name", "category", "description", "isStarred", "isClientForm"];
  let updates: Partial<FormFields> = _.pick(form, propWhitelist);
  //filter out undefined properties
  updates = _.omitBy(updates, _.isUndefined);
  
  const result = await dispatch({
    type: FORM_UPDATED,
    fetch: {
      url: `${ApiUrls.account}/${accountId}/forms/${formId}`,
      verb: "PUT",
      data: updates,
      token: true,
    },
    //For the reducer
    accountId,
    formId,
    statusKey: StatusKeys.forms,
  });

  console.log("form updated", result);
  return result;
};

//#endregion
const findShare = (state: any, clientId: string) => {
  const attorneyId = state.app.profile.uid;
  const accountId = state.app.profile.accountId;
  const shares = state.share?.shares ?? [];
  const share = shares.find((s: any) => s.sharer === clientId);
  
  if(!share){
    //This may be a client profile that hasn't accepted a share request yet
    const clientProfile = state.attorney.clients?.find((p: ClientProfile) => p.id === clientId);
    if(clientProfile) {
      const share = shares.find((s: any) => s.id === clientProfile.shareId);
      if(share) return share;
    }

    return {status: false, error: "Failed to create assignments because there is no sharing agreement between parties."}; //No sharing agreement
  } 
  if(share.accountId !== accountId && share.reviewer !== attorneyId){
    return  {status: false, error: "Failed to create assignments because current user is not a participant in provided sharing agreement."}; //I'm not part of this arrangement
  } 

  return share;
};

//#region Client Actions

export const chooseClient = (clientId: string | null) => async (dispatch: any, getState: any) => {
  let state = getState();

  if(state.attorney.currentClientId === clientId) return;
  await dispatch({type: CLIENT_CHOSEN, clientId});

  //If it's a new client, no data to load
  if(clientId === null || clientId === "new") return;
  return await dispatch(loadClient(clientId));
};

//#region Client Profile Actions

// Creates a new client and adds it to the clients/{accountId}/profiles collection (as well as other spots)
export const createClient = (newProfile: Partial<ClientProfile>) => async (dispatch: any, getState: any) => {
  const state = getState();
  const attorney = state.app.profile;
  const accountId = attorney.accountId;

  if(!accountId) {
    console.error("Failed to create client profile because no account id was found");
    return { status: "error", error: "Account is required to create a new client" };
  }

  if(newProfile.email) newProfile.email = newProfile.email.toLowerCase();

  const url = `${accountsUrlBase}/${accountId}/clients`;
  const result = await dispatch({
    type: CLIENT_CREATED,
    fetch: {
      url,
      verb: "POST",
      data: newProfile,
      token: true,
    },
    statusKey: StatusKeys.clients,
  });

  return result;
};

// Loads the list of clients from clients/{accountId}/profiles
export const loadClientList = () => async (dispatch: any, getState: any) => {
  const accountId = getState().app.profile.accountId;
  if(!accountId) return;

  const result = await dispatch({
    type: CLIENT_LIST_LOADED,
    fetch: {
      url: `${accountsUrlBase}/${accountId}/clients`,
      token: true,
    },
    //For the reducer
    accountId,
    statusKey: StatusKeys.clients,
  });

  return result;
};

export const loadClient = (clientId: string) => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;
  const url = `${ApiUrls.account}/${accountId}/clients/${clientId}`;

  const result = await dispatch({
    type: CLIENT_LOADED,
    fetch: {
      url,
      token: true,
    },
    statusKey: StatusKeys.client,
    clientId,
  });

  if(!result.isOk) console.error("Error loading client", result.error);
  return result;
};

// Loads the client values from values/{clientUid}
export const loadClientValues = (clientUid?: string, force = false) => async (dispatch: any, getState: any) => {
  if(!clientUid) return;   //TODO: deal with clients that haven't accepted an invite yet
  if(!force){
    const state = getState();
    const existing = state.attorney.clientData;
    if(existing && existing.loadedAt){
      if((Date.now() - existing.loadedAt) < REFRESH_INTERVAL) return;
    }
  }

  const accountId = getState().app.profile.accountId;
  const url = `${ApiUrls.account}/${accountId}/clients/${clientUid}/values`;
  
  const result  = await dispatch({
    type      : CLIENT_VALUES_LOADED,
    fetch: {
      url,
      token: true,
    },
    statusKey: StatusKeys.client,
    clientId: clientUid,
  });

  if(!result.isOk) console.error("Error loading client profile", result.error);
  return result;
};

// Updates the client record at clients/{accountId}/profiles
export const updateClient = (updates: Partial<ClientProfile>, clientId?: string) => async (dispatch: any, getState: any) => {
  const state = getState();
  const attorney = state.app.profile;
  const accountId = attorney.accountId;
  clientId = clientId ?? state.attorney.currentClientId;
  if(!clientId) throw new Error("No client id provided");

  const existing = state.attorney.clients.find((c: ClientProfile) => c.id === clientId);
  if(!existing) throw new Error("Client not found");

  const whitelisted = _.pick(updates, CLIENT_FIELD_WHITELIST);
  const changes = removeUndefinedProps(whitelisted);

  if(Object.keys(changes).length === 0) {
    console.error("UpdateClientProfile: No valid changes provided");
    return { status: "ok", isOk: true, data: {} };
  }

  //TODO: only send the diffs
  // const original = _.pick(existing, CLIENT_PROFILE_WHITELIST);
  // const diff = _.omitBy(changes, (v, k) => original[k] === v);

  const profile = {
    ...changes,
    modifiedBy: attorney.uid,
    modifiedAt: Date.now(),
  };

  const result = await dispatch({
    type: CLIENT_UPDATED,
    firebase: {
      type: "updateSingle",
      collection: `clients/${accountId}/profiles`,
      key: clientId,
      value: profile,
    },
    clientId,
  });

  return result;
};

// Saves changes by the attorney to they client's values at values/{clientUid}
export const updateClientValues = (clientUid: string, values: Record<string, any>) => async (dispatch: any, getState: any) => {
  if(!values || Object.keys(values).length === 0) return { status: "ok", isOk: true, data: {} };

  const state = getState();
  const attorney = state.app.profile;
  const accountId = attorney.accountId;
  
  //Remove any unchanged values, and look for values that have been unset (undefined)
  const originalValues = state.attorney.clientData?.values ?? {};
  let changes = Object.keys(values).reduce((acc: any, key: string) => {
    if(originalValues[key] === undefined) acc[key] = values[key];
    else if (values[key] === undefined && originalValues[key] !== undefined) acc[key] = null;
    else if (originalValues[key] !== values[key]) acc[key] = values[key];
    return acc;
  }, {});

  //remove any undefineds
  changes = removeUndefinedProps(changes);

  //remove any blank rows from any array values
  changes = removeBlankArrayItems(changes);  

  console.debug("Changes to client values", changes);

  const url = `${accountsUrlBase}/${accountId}/clients/${clientUid}/values`;
  const result = await dispatch({
    type: CLIENT_VALUES_UPDATED,
    fetch: {
      url,
      verb: "PUT",
      data: changes,
      token: true,
    },
    clientId: clientUid,
    statusKey: StatusKeys.clients,
    values,
  });

  return result;
}

export const inviteClient = (clientId: string, invitation: any) => async (dispatch: any, getState: any) => {
  const state = getState();
  const attorney = state.app.profile;
  const accountId = attorney.accountId;
  const url = `${accountsUrlBase}/${accountId}/clients/${clientId}/invitation`;

  //make sure email is in lower case
  if(invitation.email) invitation.email = invitation.email.toLowerCase();

  const result = await dispatch({
    type: CLIENT_INVITED,
    fetch: {
      url,
      verb: "POST",
      data: invitation,
      token: true,
    },
    clientId,
    statusKey: StatusKeys.clients,
  });

  // if(result.isOk){
  //   const { share, shareRequest, client } = result.data;
  //   dispatch({ type: SHARE_ACTIONS.SHARE_UPDATED, data: { isOk: true, data: share } });
  //   dispatch({ type: SHARE_ACTIONS.REQUEST_UPDATED, data: { isOk: true, data: shareRequest } });
  //   //Changes to the client?
  //   if(client) dispatch({ type: CLIENT_UPDATED, data: { isOk: true, data: client } });
  // }

  return result;
};

export const assignClient = (clientId: string, attorneyId: string | null) => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;
  const path = `clients/${accountId}/profiles`;

  const result = await dispatch({
    type: CLIENT_UPDATED,
    firebase: {
      type: "updateSingle",
      collection: path,
      key: clientId,
      value: { assignedTo: attorneyId },
    },
    clientId,
    statusKey: StatusKeys.clients,
  });

  return result;
};


//#endregion

//#region Client Form Actions

export const unassignForms = (clientId: string, unassignedForms: any[]) => async (dispatch: any, getState: any) => {
  const state = getState();
  const share = findShare(state, clientId);
  if(share.status === false){
    console.error("Failed to assign forms", share.error);
    return share;
  }

  let assignResult = { status: "ok", isOk: true, data: {} };

  //Need to get any items that were removed, and remove them from the database
  const originals = share.forms || [];
  
  const removed = originals.filter((f: any) => unassignedForms.find((f2: any) => f2.formId === f.formId)).map((f: any) => f.formId);

  if(removed.length > 0){
    assignResult = await dispatch({
      type: FORMS_UNASSIGNED,
      firebase: {
        type: "deleteMulti",
        collection: `shares/${share.id}/forms`,
        key: (form: any) => form.formId,
        keys: removed,
      },
      //For the reducer
      shareId: share.id,
      formIds: removed,
      statusKey: StatusKeys.shares,
    });
  }

  trackEvent(Events.forms_assigned);
  return assignResult;
};

//== Will assign forms to a client
export const assignForms = (clientId: string, items: AssignableForm[]) => async (dispatch: any, getState: any) => {
  const state = getState();
  const attorneyId = state.app.profile.uid;
  const share = findShare(state, clientId);
  if(share.status === false){
    console.error("Failed to assign forms", share.error);
    return share;
  }

  let assignResult = { status: "ok", isOk: true, data: {} };
  const now = Date.now();
  
  const models = items.map((form: Partial<AssignableForm>) => ({
    assignedBy: attorneyId,
    assignedAt: now,
    formId: form.id,
    filePath: form.filePath ?? attorneyId,
    formName: form.name,
    description: form.description ?? "",
    category: form.category ?? "",
    dueAt: form.dueOn ? dateStringToNumber(form.dueOn) : null,
    notes: form.notes ?? "",
    isClientForm: form.isClientForm ?? false,
    createdAt: now,
    createdBy: attorneyId,
    modifiedAt: now,    
    modifiedBy: attorneyId,
  }));

  assignResult = await dispatch({
    type: FORMS_ASSIGNED,
    firebase: {
      type: "createMulti",
      collection: `shares/${share.id}/forms`,
      key: (form: any) => form.formId,
      items: models,
    },
    //For the reducer
    shareId: share.id,
    statusKey: "shares",
  });

  console.log("form added", assignResult);

  trackEvent(Events.forms_assigned);
  return assignResult;
};

export const updateFormAssignment = (clientId: string, formId: string, changes: Partial<ClientForm>) => async (dispatch: any, getState: any) => {
  const state = getState();
  const attorneyId = state.app.profile.uid;
  const share = findShare(state, clientId);
  if(share.status === false){
    console.error("Failed to update form", share.error);
    return share;
  }

  const now = Date.now();
  const { description, notes, dueAt } = changes;
  const model = {
    ...(description ? { description } : {}),
    ...(notes ? { notes } : {}),
    ...(dueAt !== undefined ? { dueAt } : { dueAt: null }),
    modifiedAt: now,    
    modifiedBy: attorneyId,
  };

  return dispatch({
    type: FORM_ASSIGNMENT_UPDATED,
    firebase: {
      type: "updateSingle",
      collection: `shares/${share.id}/forms`,
      key: formId,
      value: model,
    },
    clientId, 
    shareId: share.id,
    formId,
    statusKey: StatusKeys.shares,  
  });

};

export const toggleEditClientData = (value?: boolean, regionId?: string) => async (dispatch: any, getState: any) => {
  value = value ?? !getState().attorney.isEditingClientData;
  return dispatch({ type: TOGGLE_EDIT_CLIENT_DATA, isEditing: value, regionId });
};

//#endregion

//#region Merge Actions

//Type to verify we have the required props in the merge values to be merged
type MergeValues = Record<string, any> & {
  a_outputName: string;
  a_templateUrl: string;
};

//Helper function to get the downloadable link for a file in cloud storage
const getDownloadableLink = (filePath: string, fileName: string) => async (dispatch: any, getStore: any) => {
  const state = getStore();
  const accountId = state.app.profile.accountId;

  const linkUrl = `${ApiUrls.account}/${accountId}/forms/link`;
  const linkBody = {
    filePath: `${filePath}/${fileName}`,
  };

  const linkResult = await dispatch({
    type: APP_ACTIONS.NO_OP,
    fetch: {
      url: linkUrl,
      verb: "POST",
      data: linkBody,
      token: true,
    },
    statusKey: StatusKeys.forms,
  });

  if(!linkResult.isOk){
    console.error("Failed to get download link", linkResult.error);
    return linkResult;
  }

  const downloadableUrl = linkResult.data.url;
  console.log("getTemplateLink: downloadableUrl", downloadableUrl);

  return downloadableUrl;
};

// const getTemplateAdapter = (accountId: string, templateId: string) => async (dispatch: any, getStore: any) => {
//   const state = getStore();
//   const existingAdapters = state.attorney.templateAdapters;
//   const existingAdapter = existingAdapters ? existingAdapters[templateId] : null;

//   if(existingAdapter) return existingAdapter;

//   //If there's not one already loaded in state, then first check the local adapters
//   let adapter = getLocalTemplateAdapter(templateId);
//   if(!adapter){
//     //Check for a remote template adapter
//     const path = `forms/${accountId}/templatesAdapters/${templateId}`;

//TODO: get the adapter from the database
//     const result = await dispatch({
//       type: APP_ACTIONS.NO_OP,
//       statusKey: StatusKeys.templates,
//     });
//   }
  

//   return result;
// }

//Helper function to perform the merge operation
const doMerge = (values: MergeValues, schema: FormSchema | undefined | null, blobOnly = false) => async (dispatch: any) => {
  
  //Add totals and summary data to the values, if we have the schema
  const preparedData = schema ? prepareForMerge(values, schema.layout.regions, schema.fields) : values;

  //TODO: for testing
  // console.log(JSON.stringify(preparedData));
  // return linkResult;

  const mergeResult = await dispatch({
    type: APP_ACTIONS.NO_OP,
    fetch: {
      url: MERGE_URL,
      verb: "POST",
      data: preparedData,
      token: true,
      isAttachment: true,
      blobOnly,
    },
    statusKey: StatusKeys.forms,
    getError: (result: any) => result.isOk ? null : `The system failed to export the document ${values.a_outputName}. If this error persists, please contact support for assistance.`,
  });

  return mergeResult;
};

//Helper function to upload the result of a merge operation to the client's account
const uploadMergeResult = (file: File, template: ExportTemplateSchema, clientKey?: string) => async (dispatch: any, getState: any) => {
  const state = getState();
  
  const currentClient = state.attorney.currentClient;
  const currentClientId = currentClient?.id;
  const share = findShare(state, currentClientId) as Share;

  if(!share || (share as any).status === false){
    console.error("Failed to upload document because no sharing agreement was found");
    return { status: "error", error: "No sharing agreement found" };
  }

  //See if this document has already been generated for this client
  const filter = clientKey ? (d: ClientDocument) => d.direction === "toClient" && d.templateId === template.id && d.templateClientKey === clientKey : (d: ClientDocument) => d.direction === "toClient" && d.templateId === template.id;
  const existingDoc = share.documents?.find(filter);
  let clientUpload: ClientDocument | null = null;
  if(existingDoc){
    //upload a new document version
    const updateResult = await dispatch(addClientDocumentVersion(currentClient.id, existingDoc.id, file, "re-generated")) as any; 
    clientUpload = updateResult.data as ClientDocument
  }
  else {
    //upload the document to the client
    //doc name is the file name without the extension
    const docName = file.name.replace(/\.[^/.]+$/, "");
    const docProps: ClientDocumentUpload = {
      name: docName,
      category: "Generated",
      templateId: template.id,    //flag the source template
      ...(clientKey ? { templateClientKey: clientKey } : {}), //flag the client key (if it's a couple)
      description: template.description,
      isSharedWithClient: false,
      file,
      dueAt: null,
    };
    const uploadResult = await dispatch(uploadDocument(currentClient.shareId, docProps)) as any;
    clientUpload = uploadResult.data as ClientDocument;
  }

  return clientUpload;
};

export const exportForm = (template: ExportTemplateSchema, clientKey: TemplateOptions | undefined, schema: FormSchema, andUpload = false) => async (dispatch: any, getStore: any) => {
  const state = getStore();
  let values = state.attorney.clientData?.values;

  if(!template || !template.path || !template.fileName) {
    console.error("Form export definition is missing template information");
    return;
  }
  else if(!schema || !schema.fields || !schema.layout){
    console.error("Form schema is missing or incomplete");
    return;
  }
  if(!values) {
    console.error("No values to export");
    return;
  }

  //Deal with sameAs fields/sections
  values = prepareSameAsFields(values, schema);
  
  //Get the template config, and run any adapters to prepare the data
  const templateConfig = getLocalTemplateAdapter(template.id);
  if(templateConfig){
    //this will make any adjustments to the values, based on the template configuration
    values = runValuesAdapter(template.id, values, clientKey);
  }

  //1. Get the downloadable link from the cloud function
  const downloadableUrl = await dispatch(getDownloadableLink(template.path, template.fileName));
  if(!isString(downloadableUrl)){
    console.error("Failed to get the download link for the template", template, downloadableUrl);
    return { status: "error", error: `The system failed to export the document. If this error persists, please contact support for assistance.` };
  }

  let outputFileName = template.targetFileName ?? template.fileName?.replace(/\.template/, "") ?? "GeneratedDocument.docx";
  //Add the client name to the output file name, if this is a couple
  if(clientKey){
    const client = state.attorney.currentClient;
    const clientName = clientKey === "client1" ? client?.firstName : client?.partnerFirstName;
    if(clientName) outputFileName = outputFileName.replace(/\.docx/, `_${clientName}.docx`);
  }

  const mergeValues = {
    a_outputName: outputFileName,
    a_templateUrl: downloadableUrl,
    ...values,
  };

  //2. Perform the merge
  const mergeResult = await dispatch(doMerge(mergeValues, schema, andUpload));
  console.log("export result", mergeResult);
  if(mergeResult.error){
    console.error("Error exporting form", mergeResult.error);
    return { status: "error", error: `The system failed to export the document ${outputFileName}. If this error persists, please contact support for assistance.` };
  }

  //If blobOnly, then we get the blob and upload it to the client's account
  if(andUpload){
    //3. Get the blob and turn it into a File
    const blob = mergeResult?.data as Blob;
    const mimeType = blob.type; // Use the MIME type from the blob, or specify if known
    const file = new File([blob], outputFileName, { type: mimeType });

    //4. Upload the document to the client
    const clientUpload = await dispatch(uploadMergeResult(file, template, clientKey));

    //5. Return the result
    return { isOk: true, data: clientUpload };
  }
  else {
    //If not blobOnly, then the file was downloaded to the user's device
    return mergeResult;
  }
};


//NOTE: used by generate-document-dialog.ts, which is not currently enabled in the client-card-documents.tsx
// component. The exportForm method above is what is currently used.
export const generateDocument = (template: ExportTemplate, templateOption?: TemplateOptions, blobOnly = false) => async (dispatch: any, getStore: any) => {
  const state = getStore();
  let values = state.attorney.clientData?.values;

  let schema: FormSchema | null = null;
  if(template.formId){
    const allForms: FormSummary[] = [...state.attorney.forms, ...state.attorney.commonForms.CO];
    const form = allForms.find(f => f.id === template.formId);
    const formConfigResult = await dispatch(downloadFormConfig(form)) as any;
    const formConfig = formConfigResult?.data as FormConfiguration;
    schema = formConfig?.schema
  }

  if(!template || !template.path || !template.fileName) {
    console.error("Form export definition is missing template information");
    return;
  }

  if(!values) {
    console.error("No values to export");
    return;
  }

  //Deal with sameAs fields/sections
  values = prepareSameAsFields(values, schema);

  //Get the template config, and run any adapters to prepare the data
  const templateConfig = getLocalTemplateAdapter(template.id);
  if(templateConfig){
    //this will make any adjustments to the values, based on the template configuration
    values = runValuesAdapter(template.id, values, templateOption);
  }

  //1. Get the downloadable link from the cloud function
  const downloadableUrl = await dispatch(getDownloadableLink(template.path, template.fileName));
  
  const data = {
    a_outputName: template.targetFileName ?? template.fileName?.replace(/\.template/, ""),
    a_templateUrl: downloadableUrl,
    ...values,
  };

  //2. Perform the merge
  const mergeResult = await dispatch(doMerge(data, schema, blobOnly));
  console.log("export result", mergeResult);

  return mergeResult;
};

//== Will get the current client's values, and prepare them for export using the
// form configuration provided.
export const prepareClientValues = (form: FormConfiguration | null) => async (dispatch: any, getStore: any) =>{
  const state = getStore();
  const values = state.attorney.clientData?.values;

  if(!form || !values) {
    console.warn("No form or values provided");
    return {};
  }

  if(!form.schema || !form.schema.fields || !form.schema.layout){
    console.warn("Form schema is missing or incomplete");
    return values;
  }

  const preparedData = prepareForMerge(values, form.schema.layout.regions, form.schema.fields);

  return preparedData;
}

//#endregion

//#region Client Document Actions


//handled by updateClientDocument
// export const assignClientDocument = (clientId: string, documentId: string, attorneyId: string | null) => async (dispatch: any, getState: any) => {
//   const state = getState();
//   const attorneyId = state.app.profile.uid;
//   const share = findShare(state, clientId);
//   if(share.status === false){
//     console.error("Failed to add document", share.error);
//     return share;
//   }

//   const path = `shares/${share.id}/documents`;

//   const result = await dispatch({
//     type: CLIENT_DOC_UPDATED,
//     firebase: {
//       type: "updateSingle",
//       collection: path,
//       key: documentId,
//       value: { assignedTo: attorneyId },
//     },
//     clientId,
//     shareId: share.id,
//     docId: documentId,
//     statusKey: StatusKeys.clients,
//   });

//   return result;
// };

export const addClientDocument = (clientId: string, docProps: ClientDocumentUpload) => async (dispatch: any, getState: any) => {
  const state = getState();
  const attorneyId = state.app.profile.uid;
  const share = findShare(state, clientId);
  if(share.status === false){
    console.error("Failed to add document", share.error);
    return share;
  }

  const { file, ...props} = docProps;
  if(!file) throw new Error("No file provided");

  const path = `shares/${share.id}/documents`;
  const now = Date.now();
  const ext = file.name.split(".").pop() ?? "pdf";
  
  const fileId = getFirestoreId(path);

  //NOTE: Need to upload the document first, so the sync will be able to download the doc from storage
  //TODO: change the flow so the sync can use the file from this spot, rather than re-downloading the file
  // from firebase storage after this action uploads it.

  //get the file extension from the filename
  const docFileName = `${fileId}.${ext}`; //the storage file name will be the id of the document
  const filePath = `${path}/${docFileName}`;
  const fileResult = await dispatch({
    type: CLIENT_DOC_UPLOADED,
    firebase: {
      type: "uploadFile",
      path: filePath,
      value: file,
    },
    statusKey: StatusKeys.clientDocuments,
  });

  if(!fileResult.isOk) {
    throw new Error("Failed to upload file");
  }

  //First, add a firestore doc to the /shares/{shareId}/documents collection
  const model: DocumentSchema = {
    ...props,
    ...(props.dueAt ? { dueAt: props.dueAt } : {}),
    direction: "toClient",
    filePath: path,
    // storagePath: filePath,    //the full path to the location in firebase storage
    fileName: docFileName,
    originalFileName: file.name,
    fileExtension: ext,
    createdBy: attorneyId,
    createdAt: now,
    modifiedBy: attorneyId,
    modifiedAt: now,
    version: 1,
    versionChanges: "original",
  };

  const result = await dispatch({
    type: CLIENT_DOC_ADDED,
    firebase: {
      type: "create",
      collection: path,
      key: fileId,
      value: model,
    },
    clientId,
    shareId: share.id,
    statusKey: StatusKeys.clientDocuments,
    // file: file,
  });

  console.log("document added", result);
  if(!result.isOk) {
    throw new Error("Failed to add document");
  }

  // trackEvent(Events.form_created);
  return result;
};

export const addClientDocumentVersion = (clientId: string, docId: string, file: File, versionChanges: string) => async (dispatch: any, getState: any) => {
  if(!file) throw new Error("No file provided");

  const state = getState();
  const attorneyId = state.app.profile.uid;
  const share = findShare(state, clientId);
  if(share.status === false){
    console.error("Failed to add document", share.error);
    return share;
  }
  const doc: ClientDocument = share.documents.find((d: ClientDocument) => d.id === docId);
  if(!doc){
    console.error("Failed to add document version, document not found", docId);
    return { status: "error", error: "Document not found" };
  }

  const path = `shares/${share.id}/documents`;
  // const path = `shares/${share.id}/documents/${docId}`;
  const now = Date.now();

  //first, upload the new version of the file
  const versionNumber = (doc.versions?.length ?? 0) + 2;
  const ext = file.name.split(".").pop();
  const versionFileName = `${doc.id}_v${versionNumber}.${ext}`; //the storage file name will be the id of the document
  const filePath = `${path}/${versionFileName}`;
  const fileResult = await dispatch({
    type: CLIENT_DOC_UPLOADED,
    firebase: {
      type: "uploadFile",
      path: filePath,
      value: file,
    },
    statusKey: StatusKeys.clientDocuments,
  });

  if(!fileResult.isOk){
    throw new Error("Failed to upload file version");
  }

  //Now, update the document in Firestore with the new version
  const wasOriginal = doc.version === 1;
  const docVersion = {
    fileName: doc.fileName ?? `${doc.id}.${ext}`,
    originalFileName: doc.originalFileName,
    version: doc.version,
    versionChanges: wasOriginal ? "original" : doc.versionChanges ?? "",
    createdAt: wasOriginal ? doc.createdAt : doc.modifiedAt,
    createdBy: doc.createdBy,
    replacedAt: now,
  };

  const changes = {
    modifiedBy: attorneyId,
    modifiedAt: now,
    version: versionNumber,
    fileName: versionFileName,
    originalFileName: file.name,
    submittedAt: null,    //clear out submitted at
    // downloadedAt: null,   //clear out downloaded at
    viewed: {},           //clear out viewed
    downloaded: {},       //clear out downloaded
    versionChanges: versionChanges,
    versions: [...(doc.versions ?? []), docVersion],
  };

  return dispatch({
    type: CLIENT_DOC_UPDATED,
    firebase: {
      type: "updateSingle",
      collection: `shares/${share.id}/documents`,
      key: docId,
      value: changes,
    },
    clientId, 
    shareId: share.id,
    docId: docId,
    statusKey: StatusKeys.clientDocuments,  
  });

};

export const updateClientDocument = (clientId: string, docId: string, changes: Partial<DocumentSchema>) => async (dispatch: any, getState: any) => {
  const state = getState();
  const attorney = state.app.profile;
  const share = findShare(state, clientId);
  if(share.status === false){
    console.error("Failed to update document", share.error);
    return share;
  }

  const timestamp = Date.now();
  const { name, dueAt, isSharedWithClient, description, assignedTo } = changes;
  const updates = {
    ...(name ? { name } : {}),
    ...(description ? { description } : {}),
    ...(dueAt !== undefined ? { dueAt } : {}),    //need to account for null here
    ...(isSharedWithClient !== undefined ? { isSharedWithClient } : {}),
    ...(assignedTo !== undefined ? { assignedTo } : {}),
    modifiedBy: attorney.uid,
    modifiedAt: timestamp,
  };

  return dispatch({
    type: CLIENT_DOC_UPDATED,
    firebase: {
      type: "updateSingle",
      collection: `shares/${share.id}/documents`,
      key: docId,
      value: updates,
    },
    clientId, 
    shareId: share.id,
    docId: docId,
    statusKey: StatusKeys.clientDocuments,  
  });

};


//== This is called when client documents have been synced with cloud storage.
// it will update the docs in the db to indicate that they have been synced, when they were
// synced, and where they are stored.
export const clientDocumentsSynced = (clientId: string, updates: any[], timestamp?: number) => async (dispatch: any, getState: any) => {
  const state = getState();
  const share = findShare(state, clientId);
  if(share.status === false){
    console.error("Failed to update document", share.error);
    return share;
  }

  for(const update of updates){
    const { docId, fileId, path, eTag, error } = update;
    if(error){
      console.error("Error syncing document", error);
      continue;
    }

    //Don't update modifiedAt so that we don't trigger a re-sync
    const changes = {
      syncPath: path,
      syncId: fileId,
      syncTag: eTag,
      syncedAt: timestamp ?? Date.now(),
    };

    await dispatch({
      type: CLIENT_DOC_UPDATED,
      firebase: {
        type: "updateSingle",
        collection: `shares/${share.id}/documents`,
        key: docId,
        value: changes,
      },
      clientId, 
      shareId: share.id,
      docId,
      statusKey: StatusKeys.clientDocuments,  
    });
  }
};

//=== Adds a Document Request, which is the Attorney asking the Client for a specific
// document. This gets inserted into the /shares/{shareId}/documents collection, but is missing the
// file information until the client fulfills the request, and uploads the document.
export const addClientDocumentRequest = (clientId: string, docProps: Partial<ClientDocumentUpload>) => async (dispatch: any, getState: any) => {
  const state = getState();
  const attorneyId = state.app.profile.uid;
  const share = findShare(state, clientId);
  if(share.status === false){
    console.error("Failed to add document", share.error);
    return share;
  }

  const path = `shares/${share.id}/documents`;
  const now = Date.now();

  const model: Partial<DocumentSchema> = {
    ...docProps,
    // ...(props.dueAt ? { dueAt: props.dueAt } : {}),
    direction: "fromClient",
    isSharedWithClient: true,
    requestedBy: attorneyId,
    requestedAt: now,
  };

  const result = await dispatch({
    type: CLIENT_DOC_ADDED,
    firebase: {
      type: "create",
      collection: path,
      value: model,
    },
    clientId,
    shareId: share.id,
    statusKey: StatusKeys.clientDocuments,
  });

  return result;
};

//#endregion

//#region Client Date Actions

export const addClientDate = (clientId: string, dateItem: ClientEditableDate) => (dispatch: any, getState: any) => {
  const state = getState();
  const attorney = state.app.profile;
  const share = findShare(state, clientId);
  if(share.status === false){
    console.error("Failed to create date, share not found", share.error);
    return share;
  }

  const timestamp = Date.now();
  const date: ClientDateSchema = {
    ...dateItem,
    createdByType: "reviewer",
    createdBy: attorney.uid,
    createdAt: timestamp,
    modifiedBy: attorney.uid,
    modifiedAt: timestamp,
  };

  return dispatch({
    type: CLIENT_DATE_ADDED,
    firebase: {
      type: "create",
      collection: `shares/${share.id}/dates`,
      value: date,
    },
    clientId, 
    shareId: share.id, 
    statusKey: StatusKeys.dates,  
  });
};

export const updateClientDate = (clientId: string, dateId: string, updates: ClientEditableDate) => (dispatch: any, getState: any) => {
  const state = getState();
  const attorney = state.app.profile;
  const share = findShare(state, clientId);
  const existing = share?.dates?.find((d: ClientDateSchema) => d.id === dateId);
  if(!existing) return { status: "error", error: "Date not found" };

  const timestamp = Date.now();
  const toUpdate: Partial<ClientDateSchema> = {
    // ...existing,
    ...updates,
    modifiedBy: attorney.uid,
    modifiedAt: timestamp,
  };

  return dispatch({
    type: CLIENT_DATE_UPDATED,
    firebase: {
      type: "updateSingle",
      collection: `shares/${share.id}/dates`,
      key: dateId,
      value: toUpdate,
    },
    dateId, 
    shareId: share.id, 
    statusKey: StatusKeys.dates,  
  });
};

export const deleteClientDate = (clientId: string, dateId: string) => (dispatch: any, getState: any) => {
  const state = getState();
  const attorney = state.app.profile;
  const share = findShare(state, clientId);
  const existing = share?.dates?.find((d: ClientDateSchema) => d.id === dateId);
  if(!existing) return { status: "error", error: "Date not found" };

  //Make sure the date was created by this user (this is re-confirmed on the server)
  const uid = attorney.uid;
  if(existing.createdBy !== uid) return { status: "error", error: "Date not created by current user" };

  return dispatch({
    type: CLIENT_DATE_DELETED,
    firebase: {
      type: "deleteSingle",
      collection: `shares/${share.id}/dates`,
      key: dateId,
    },
    shareId: share.id,
    dateId,
    statusKey: StatusKeys.dates,
  });
};

//#endregion

//#region Client Note Actions

export const loadClientNotes = (clientId: string) => async (dispatch: any, getState: any) => {
  const state = getState();
  const attorney = state.app.profile;
  const accountId = attorney.accountId;

  const result = await dispatch({
    type: CLIENT_NOTES_LOADED,
    firebase: {
      type: "getList",
      collection: `clients/${accountId}/profiles/${clientId}/notes`,
      hydrate: true,  
      mayNotExist: true,    
    },
    //For the reducer
    clientId,
    statusKey: StatusKeys.notes,
  });

  return result;
};

export const addClientNote = (clientId: string, note: string, tags: string[] = []) => (dispatch: any, getState: any) => {
  const state = getState();
  const attorney = state.app.profile;
  const accountId = attorney.accountId;

  const timestamp = Date.now();
  const noteItem = {
    // clientId,
    content: note,
    createdBy: attorney.uid,
    createdAt: timestamp,
    modifiedBy: attorney.uid,
    modifiedAt: timestamp,
    tags,
  };

  return dispatch({
    type: CLIENT_NOTE_ADDED,
    firebase: {
      type: "create",
      collection: `clients/${accountId}/profiles/${clientId}/notes`,
      // key: clientId,
      value: noteItem,
    },
    clientId, 
    statusKey: StatusKeys.notes,  
  });
};

export const updateClientNote = (clientId: string, noteId: string, changes: Partial<ClientNoteSchema>) => (dispatch: any, getState: any) => {
  if(!noteId) return { isOk: false, status: "error", error: "Note id is required" };
  const state = getState();
  const attorney = state.app.profile;
  const accountId = attorney.accountId;
  const client = state.attorney.clients.find((c: ClientProfile) => c.id === clientId);
  if(!client) return { isOk: false, status: "error", error: "Client not found" };
  // const existing = client.notes?.find((n: any) => n.id === noteId);
  // if(!existing) return { isOk: false, status: "error", error: "Note not found" };

  const timestamp = Date.now();
  const { content, tags, isStarred = false } = changes;
  const updates = {
    content,
    tags,
    isStarred,
    modifiedBy: attorney.uid,
    modifiedAt: timestamp,
  };

  return dispatch({
    type: CLIENT_NOTE_UPDATED,
    firebase: {
      type: "updateSingle",
      collection: `clients/${accountId}/profiles/${clientId}/notes`,
      key: noteId,
      value: updates,
    },
    clientId, 
    noteId: noteId,
    statusKey: StatusKeys.notes,  
  });
};

export const deleteClientNote = (clientId: string, noteId: string) => (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;

  return dispatch({
    type: CLIENT_NOTE_DELETED,
    firebase: {
      type: "deleteSingle",
      collection: `clients/${accountId}/profiles/${clientId}/notes`,
      key: noteId,
    },
    clientId, 
    noteId,
    statusKey: StatusKeys.notes,  
  });
};

//#endregion

//#endregion