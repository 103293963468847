import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment, { Moment } from "moment";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import { CheckCircle, CheckCircleOutlineOutlined, Close, Edit, Info, Share } from "@mui/icons-material";
import { toast } from "react-toastify";
import { ClientDocument } from "types";
import { useAppContext } from "../app/app-context";
import { formatShortDate } from "helpers/general-helpers";
import DocumentVersionsButton from "./document-versions-button";
import ShareDocumentDialog from "../collaborator/parts/client-documents-card/share-document-dialog";
import { updateClientDocument } from "store/actions/attorney-actions";
import { parseFbResult } from "store/store-helpers";
import { useClientIdentifiers } from "../collaborator/client-context";

const DocumentPropertiesTable = ({ document }: { document: ClientDocument}) => {
  const dispatch = useDispatch();
  const { clientId } = useParams<{clientId: string}>();
  const { isReviewer } = useAppContext();
  const { clientUid } = useClientIdentifiers();
  const documentStatus = useMemo(() => docStatus(document), [document]);
  const [isSharing, setSharing] = useState<boolean>(false);
  const [nextDueAt, setNextDueAt] = useState<any>(document.dueAt ? moment(document.dueAt) : null);
  const [isConfirming, setConfirming] = useState<boolean>(false);
  const isClientUpload = document?.direction === "fromClient";
  
  const downloadedAt = useMemo(() => {
    if(clientUid){
      return document.downloaded ? document.downloaded[clientUid] ?? null : null;
    }
  }, [clientUid, document.downloaded]);

  const handleDateChange = (value: Moment | null) => {
    let numVal: number | undefined | null = value?.valueOf();
    if(!numVal || isNaN(numVal)) numVal = null;

    if(numVal !== document.dueAt){
      console.log("change date to: ", numVal ? new Date(numVal) : null );
      setNextDueAt(value);
      setConfirming(true);
    }
  }

  const handleCancelDueDate = () => {
    setNextDueAt(moment(document.dueAt));
    setConfirming(false);
  }

  const handleSaveDueDate = async () => {
    // console.log("save date change to: ", nextDueAt ? new Date(nextDueAt) : null );
    const updates = { dueAt: nextDueAt?.valueOf() ?? null };
    const result = await dispatch(updateClientDocument(clientId!, document.id, updates));
    const pr = parseFbResult(result);

    if(pr.isOk){
      toast.success("Due date updated successfully");
      setNextDueAt(moment(document.dueAt));
      setConfirming(false);
    }
  }

  return (
    <TableContainer sx={{ border: "1px solid", borderRadius: 2, borderColor: "grey.300", overflowX: "hidden"}}>
      <Table>
        <TableBody>
          {!isClientUpload && (
            <TableRow>
              <TableCell sx={{ color: "grey.500", fontSize: "1rem", textTransform: "uppercase"}}>Due</TableCell>
              <TableCell>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                  { document.dueAt ? formatShortDate(document.dueAt) : <Typography fontSize="1.2rem" color="grey.600" fontStyle="italic">Not set</Typography> }
                  { isReviewer && <DatePicker value={nextDueAt} onChange={handleDateChange} sx={{ width: 48, "& input": { display: "none" }, "& fieldset" : { display: "none" } }} /> }

                  <Dialog open={isConfirming} onClose={() => setConfirming(false)}>
                    <DialogTitle>Change Due Date</DialogTitle>
                    <DialogContent>
                      <Typography>Are you sure you want to change the due date to <strong>{moment(nextDueAt).format("MMM D, YYYY")}</strong>?</Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCancelDueDate} variant="outlined" color="primary" startIcon={<Close />}>Cancel</Button>
                      <Button onClick={handleSaveDueDate} variant="outlined" color="primary" startIcon={<CheckCircle />}>Confirm</Button>
                    </DialogActions>
                  </Dialog>
                  
                </Grid>
              </TableCell>
            </TableRow>
          )}
          {/* Shared Row (for client documents) */}
          {!isClientUpload && (
            <TableRow>
              <TableCell sx={{ color: "grey.500", fontSize: "1rem", textTransform: "uppercase"}}>Status</TableCell>
              <TableCell>
              {documentStatus === "not-shared" && (
                <>
                  <Chip label="Not Shared" variant="outlined" color="warning"  sx={{ py: 2, px: 1 }} />
                  <Tooltip title="Share this document with the client">
                    <IconButton onClick={() => setSharing(true)} size="small" color="primary" sx={{ ml: 2 }}><Share fontSize="small" /></IconButton>
                  </Tooltip>
                  {isSharing && <ShareDocumentDialog item={document} open={isSharing} onClose={() => setSharing(false)} />}
                </>
              )}
              {documentStatus === "approved" && (
                <Chip label="Approved" color="primary" icon={<CheckCircleOutlineOutlined fontSize="small" />}  sx={{ py: 2, px: 1 }} />
              )}
              {documentStatus === "client-review" && (
                <Chip label="Client Review" variant="outlined" color="secondary"  sx={{ py: 2, px: 1 }} />
              )}
              </TableCell>
            </TableRow>
          )}

          {(isReviewer && !isClientUpload) && (
            // TODO: Will need to handle for client uploads when they can upload versions
            <TableRow>
              <TableCell sx={{ color: "grey.500", fontSize: "1rem", textTransform: "uppercase"}}>Version</TableCell>
              <TableCell sx={{ display: "flex", alignItems: "center", gap: 4}}>
                {document.version}
                {/* TODO: Add a drop down button to download previous versions */}
                <DocumentVersionsButton document={document} />
              </TableCell>
            </TableRow> 
          )}

          {(isReviewer && !isClientUpload) && (
            <TableRow>
              <TableCell sx={{ color: "grey.500", fontSize: "1rem", textTransform: "uppercase"}}>Downloaded</TableCell>
              <TableCell sx={{ display: "flex", alignItems: "center" }}>
                {downloadedAt ? formatShortDate(downloadedAt) : <Typography fontSize="1.2rem" color="grey.600" fontStyle="italic">Not yet</Typography>}
                <Tooltip title="When the document was downloaded by the client">
                  <Info fontSize="small" sx={{ color: "grey.500", ml: 2 }} />
                </Tooltip>
              </TableCell>
            </TableRow>  
          )}
          {(isClientUpload && !!document.createdAt) && (
            <TableRow>
              <TableCell sx={{ color: "grey.500", fontSize: "1rem", textTransform: "uppercase"}}>Uploaded</TableCell>
              <TableCell sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontSize="1.2rem">{formatShortDate(document.createdAt)}</Typography>
              </TableCell>
            </TableRow> 
          )}
          {(isClientUpload && !document.createdAt && !!document.requestedAt) && (
            <TableRow>
              <TableCell sx={{ color: "grey.500", fontSize: "1rem", textTransform: "uppercase"}}>Requested</TableCell>
              <TableCell sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontSize="1.2rem">{formatShortDate(document.requestedAt)}</Typography>
              </TableCell>
            </TableRow> 
          )}

          <TableRow>
            <TableCell sx={{ color: "grey.500", fontSize: "1rem", textTransform: "uppercase"}}>Description</TableCell>
            <TableCell>{document.description}</TableCell>
          </TableRow>
                            
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentPropertiesTable;

const docStatus = (document: ClientDocument) => {
  if(!document.isSharedWithClient){
    return "not-shared";
  }
  else if(document.submittedAt){
    return "approved";
  }
  else {
    return "client-review";
  }
};