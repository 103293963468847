// import _ from 'lodash';
import { APP_ACTIONS } from '../actions/action-types';
import { ATTORNEY_ACTIONS } from '../actions/attorney-actions';
import { PETITIONER_ACTIONS } from '../actions/petitioner-actions';
import { SHARE_ACTIONS } from '../actions/share-actions';
import helpers, { immAddOrReplace, immRemoveItem } from './reducer-helpers';

// const STATUS_DEFAULT  = { isWorking: false, error: null, isEmpty: false };

//==================
// Initial Reducer State
const INIT_STATE  = {
  requests      : null,   //Requests are sent requests (e.g. sent by a lawyer)
  invitations   : null,   //Invitations are received requests (e.g. received from a lawyer)
  shares        : null,   //Actual share agreements  
  formConfigs: {},      //Form configurations for the share forms - Record<docId, FormConfig>
};

//==================
// Reducer Methods

//#region Share Requests
function requestsLoaded(state, action){
  if(action.isOk){
    return {
      ...state,
      requests      : action.data,
    }
  }
  return state;
}

function receivedLoaded(state, action){
  return {
    ...state,
    invitations   : action.data,
  };
}

function receivedUpdated(state, action){
  return {
    ...state,
    invitations   : helpers.immAddOrReplace(state.invitations, action.data, i => i.id === action.data.id),
  }
}
//#endregion

//TODO: NEXT - for the new acceptInviteApi action
function inviteAccepted(state, action){
  //old version triggers the following types
  //SHARES_LOADED, RECEIVED_UPDATED 
  //the action returns with the share and shareRequest
  if(!action.isOk){
    console.error("Failed to accept invite", action.error);
    return state;
  }

  const { share, invite } = action.data;
  if(!share || !invite) return state;

  //update the share
  const updatedShares = helpers.immAddOrReplace(state.shares, share, s => s.id === share.id);
  //update the invite
  const updatedInvites = helpers.immAddOrReplace(state.invitations, invite, i => i.id === invite.id);

  return {
    ...state,
    shares: updatedShares,
    invitations: updatedInvites,
  }
}

//#region Shares
function sharesLoaded(state, action){
  if(action.isOk){    
    //merge the incoming shares into any existing items
    const updates = helpers.immMergeArrays(state.shares, action.data, s => s.id);
    return {
      ...state,
      shares      : updates, //action.data,
    }
  }
  
  return state;
}

function shareLoaded(state, action){
  if(action.isOk && action.data){
    let updated = action.data;
    const existing = state.shares.find(s => s.id === action.data.id);
    if(existing) updated = { ...existing, ...action.data };
    const nextShares = action.isReload === true ? action.data : helpers.immAddOrReplace(state.shares, updated, s => s.id === updated.id);
    return {
      ...state,
      shares      : nextShares,
    }
  }
  return state;
}

function shareProfileLoaded(state, action) {
  if(!action.isOk){
    console.error("failed to load share profile", action.error);
    return state;
  } 

  const { shareId, uid, data } = action;
  const share = state.shares.find(s => s.id === shareId);
  if(!share || uid !== share.reviewer) return state;

  const { displayName, email, photoURL } = data;
  const updatedShare = { ...share, reviewerInfo: { id: uid, displayName, email, photoURL } };

  return {
    ...state,
    shares: helpers.immAddOrReplace(state.shares, updatedShare, s => s.id === shareId),
  };
}

function shareCreated(state, action){
  if(!action.isOk || !action.data) return state;
  let item = action.data;
  if(!item.forms) item.forms = [];  //initialize with the forms array
  return {
    ...state,
    shares      : [item, ...(state.shares || [])],
  }
}

function shareUpdated(state, action){
  if(!action.isOk) return state;

  const existing = state.shares.find(s => s.id === action.data.id);
  const updated = { ...existing, ...action.data };
  const updatedShares = helpers.immAddOrReplace(state.shares, updated, s => s.id === action.data.id)
  return {
    ...state,
    shares: updatedShares,
  };
}

function shareDeleted(state, action){
  return {
    ...state,
    shares      : helpers.immRemoveItem(state.shares, s => s.id === action.id),
  }
}
//#endregion

//called when a new attorney client account is created
function clientCreated(state, action){
  if(!action.isOk) return state;
  const { share, shareRequest } = action.data;
  if(!share || !shareRequest) return state;

  return {
    ...state,
    requests: [shareRequest, ...(state.requests || [])],
    shares: [share, ...(state.shares || [])],
  }
}

//#region Inviting a user
function requestCreated(state, action){
  if(!action.isOk) return state;
  
  return {
    ...state,
    requests      : [action.data, ...(state.requests || [])],
  };
}


function requestUpdated(state, action){
  if(!action.isOk) return state;

  const updatedRequests = helpers.immAddOrReplace(state.requests, action.data, r => r.id === action.data.id);
  return {
    ...state,
    requests: updatedRequests,
  };
}

function requestDeleted(state, action){
  return {
    ...state,
    requests      : helpers.immRemoveItem(state.requests, i => i.id === action.id),
  };
}

//#endregion

//#region Client Share Items

//When a client is loaded, it includes a current version of the share for that client, so update state here
function clientLoaded(state, action){
  if(!action.isOk) return state;
  const { share } = action.data;
  if(!share) return state;

  //Share will include everything current, so just replace the existing share
  return {
    ...state,
    shares: helpers.immAddOrReplace(state.shares, share, s => s.id === share.id),
  }
}

//When a client is invited, the share and share request are updated to reflect details of the invitation
function clientInvited(state, action){
  if(!action.isOk) return state;
  const { share, shareRequest } = action.data;

  const existingShare = state.shares.find(s => s.id === share.id);
  const existingRequest = state.requests.find(r => r.id === shareRequest.id);

  const newShare = existingShare ? { ...existingShare, ...share } : null;
  const newRequest = existingRequest ? { ...existingRequest, ...shareRequest } : null;

  const newShares = newShare ? helpers.immAddOrReplace(state.shares, newShare, s => s.id === newShare.id) : state.shares;
  const newRequests = newRequest ? helpers.immAddOrReplace(state.requests, newRequest, r => r.id === newRequest.id) : state.requests;

  return {
    ...state,
    requests: newRequests,
    shares: newShares,
  }
}

//#region Form & Document Assignments

function formsAssigned(state, action){
  const share = state.shares.find(s => s.id === action.shareId);
  if(share){
    //find update any existing items, and add any new items
    const added = action.data.filter(f => !share.forms?.some(sf => sf.id === f.id));
    const updated = action.data.filter(f => share.forms?.some(sf => sf.id === f.id));
    const updates = (share.forms ?? []).map(f => updated.find(u => u.id === f.id) ?? f);

    const updatedShare = {
      ...share, 
      forms: [
        ...updates,
        ...added,
      ]
    };

    return {
      ...state,
      shares: helpers.immAddOrReplace(state.shares, updatedShare, s => s.id === action.shareId),
    }
  }
  return state;
}

function formsUnassigned(state, action){
  const share = state.shares.find(s => s.id === action.shareId);
  if(share){
    const removed = action.formIds;
    const updatedShare = {
      ...share, 
      forms: share.forms.filter(f => !removed.includes(f.id)),
    };

    return {
      ...state,
      shares: helpers.immAddOrReplace(state.shares, updatedShare, s => s.id === action.shareId),
    }
  }
  return state;
}

function formAssignmentUpdated(state, action){
  const share = state.shares.find(s => s.id === action.shareId);
  const form = share?.forms?.find(f => f.id === action.formId);
  const updates = {...form, ...action.data};

  if(share){
    const updatedShare = {
      ...share, 
      forms: immAddOrReplace(share.forms, updates, f => f.id === action.formId),
    };

    return {
      ...state,
      shares: helpers.immAddOrReplace(state.shares, updatedShare, s => s.id === action.shareId),
    }
  }
  return state;
}

//Triggered when a document on the share has been updated.
//for example, when the petitioner marks the document as approved.
function documentUpdated(state, action){
  if(action.error) return state;    //handled by the status hook

  const share = state.shares.find(s => s.id === action.shareId);
  const doc = share?.documents?.find(d => d.id === action.documentId);
  if(!doc) return state;
  
  const updatedShare = {
    ...share, 
    documents: immAddOrReplace(share.documents, action.data, d => d.id === action.documentId),
  };

  return {
    ...state,
    shares: immAddOrReplace(state.shares, updatedShare, s => s.id === action.shareId),
  };
}

//#endregion

//#region Client Dates

function clientDateAdded(state, action){
  if(action.error) return state;    //handled by the status hook

  const share = state.shares.find(s => s.id === action.shareId);
  if(!share) return state;
  
  const updatedShare = {
    ...share, 
    dates: [
      ...share.dates ?? [],
      action.data,
    ]
  };

  return {
    ...state,
    shares: helpers.immAddOrReplace(state.shares, updatedShare, s => s.id === action.shareId),
  }
}

function clientDateUpdated(state, action){
  if(action.error) return state;    //handled by the status hook

  const share = state.shares.find(s => s.id === action.shareId);
  const date = share?.dates?.find(d => d.id === action.dateId);
  if(!date) return state;
  
  const updatedShare = {
    ...share, 
    dates: immAddOrReplace(share.dates, action.data, d => d.id === action.dateId),
  };

  return {
    ...state,
    shares: immAddOrReplace(state.shares, updatedShare, s => s.id === action.shareId),
  }
}

function clientDateDeleted(state, action){
  if(action.error) return state;
  const share = state.shares.find(s => s.id === action.shareId);
  if(!share) return state;
  const updatedShare = { ...share, dates: immRemoveItem(share.dates, d => d.id === action.dateId), };
  return {
    ...state,
    shares: immAddOrReplace(state.shares, updatedShare, s => s.id === action.shareId),
  }
}

//#endregion

//#region Client Documents

function documentAdded(state, action){
  if(action.error) return state;    //handled by the status hook

  const share = state.shares.find(s => s.id === action.shareId);
  if(!share) return state;

  //This may also be an update of a document, if they're fulfilling a document request
  //if there is a file passed in with the action, add it here so we don't need to re-download the file
  const doc = action.data;
  if(action.file) doc.file = action.file;
  
  const updatedShare = {
    ...share, 
    documents: immAddOrReplace(share.documents, action.data, d => d.id === action.data.id),
  };

  return {
    ...state,
    shares: helpers.immAddOrReplace(state.shares, updatedShare, s => s.id === action.shareId),
  }
}

function documentEdited(state, action){
  if(action.error) return state;    //handled by the status hook

  const share = state.shares.find(s => s.id === action.shareId);
  const doc = share?.documents?.find(d => d.id === action.docId);
  if(!doc) return state;
  
  const updatedShare = {
    ...share, 
    documents: immAddOrReplace(share.documents, action.data, d => d.id === action.docId),
  };

  return {
    ...state,
    shares: immAddOrReplace(state.shares, updatedShare, s => s.id === action.shareId),
  }
}

function documentDeleted(state, action){
  if(action.error) return state;
  const share = state.shares.find(s => s.id === action.shareId);
  if(!share) return state;
  const updatedShare = { ...share, documents: immRemoveItem(share.documents, d => d.id === action.docId), };
  return {
    ...state,
    shares: immAddOrReplace(state.shares, updatedShare, s => s.id === action.shareId),
  }

}
//#endregion

//#endregion

function formConfigLoaded(state, action){
  const { shareId, formId, error } = action;
  const share = state.shares.find(s => s.id === shareId);
  if(!share) return state;

  const form = share.forms.find(f => f.id === formId);
  if(!form) return state;

  const updatedForm = error ? {...form, configError: error } : { ...form, config: action.data };
  const updatedShare = {
    ...share,
    forms: helpers.immUpdateArray(share.forms, form, updatedForm),
  };

  //TODO: remove the above, just use the form configs here
  const value = error ? { isError: true, error } : action.data;
  const configs = { ...state.formCongifs, [formId]: value };

  return {
    ...state,
    shares: helpers.immUpdateArray(state.shares, share, updatedShare),
    formConfigs: configs,
  };
}

function onSignedOut(state, action){
  return {...INIT_STATE };
}

//==================
// Reducer creation

const shareReducer  = {
  [SHARE_ACTIONS.RECEIVED_LOADED] : receivedLoaded,
  [SHARE_ACTIONS.RECEIVED_UPDATED]: receivedUpdated,

  [SHARE_ACTIONS.SHARES_LOADED]   : sharesLoaded,
  [SHARE_ACTIONS.SHARE_LOADED]    : shareLoaded,    //single item loaded
  [SHARE_ACTIONS.SHARE_PROFILE_LOADED]: shareProfileLoaded,  //profile info loaded for a share reviewer
  [SHARE_ACTIONS.SHARE_CREATED]   : shareCreated,
  [SHARE_ACTIONS.SHARE_UPDATED]   : shareUpdated,
  [SHARE_ACTIONS.SHARE_DELETED]   : shareDeleted,

  [SHARE_ACTIONS.INVITE_ACCEPTED] : inviteAccepted,   //new version of acceptInvite with the API

  [SHARE_ACTIONS.REQUESTS_LOADED] : requestsLoaded,
  [SHARE_ACTIONS.REQUEST_CREATED] : requestCreated,
  [SHARE_ACTIONS.REQUEST_UPDATED] : requestUpdated,
  [SHARE_ACTIONS.REQUEST_DELETED] : requestDeleted,

  [ATTORNEY_ACTIONS.CLIENT_LOADED]: clientLoaded,
  [ATTORNEY_ACTIONS.FORMS_ASSIGNED]: formsAssigned,
  [ATTORNEY_ACTIONS.FORMS_UNASSIGNED]: formsUnassigned,
  [ATTORNEY_ACTIONS.FORM_ASSIGNMENT_UPDATED]: formAssignmentUpdated,
  [ATTORNEY_ACTIONS.CLIENT_CREATED]: clientCreated,
  [ATTORNEY_ACTIONS.CLIENT_INVITED]: clientInvited,
  [ATTORNEY_ACTIONS.CLIENT_DATE_ADDED]: clientDateAdded,
  [ATTORNEY_ACTIONS.CLIENT_DATE_UPDATED]: clientDateUpdated,
  [ATTORNEY_ACTIONS.CLIENT_DATE_DELETED]: clientDateDeleted,
  
  //TODO: remove attorney action version, and switch to using share action.
  [ATTORNEY_ACTIONS.CLIENT_DOC_ADDED]: documentAdded,
  [SHARE_ACTIONS.DOC_UPLOADED]: documentAdded,

  //TODO: remove the attorney action, and use the share action for both
  [ATTORNEY_ACTIONS.CLIENT_DOC_UPDATED]: documentEdited,
  [SHARE_ACTIONS.DOC_UPDATED]: documentEdited,

  [PETITIONER_ACTIONS.PET_DOCUMENT_DOWNLOADED]: documentUpdated,
  [PETITIONER_ACTIONS.PET_DOCUMENT_SUBMITTED]: documentUpdated,

  [SHARE_ACTIONS.DOC_DELETED]: documentDeleted,

  [SHARE_ACTIONS.FORMCONFIG_LOADED]: formConfigLoaded,

  [APP_ACTIONS.SIGNED_OUT]    : onSignedOut,
};

export default helpers.createReducer(INIT_STATE, shareReducer);