import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Button, MenuItem, Typography } from "@mui/material";
import WordIcon from "./word-icon";
import { ExportTemplateSchema } from "types/schema";
import MenuButton from "components/menu-button";
import { useClient } from "../collaborator/client-context";

export type ExportOption = {
  id: string;
  name: string;
  desc: string;
  isRequired: boolean;
  locKey: string;     //co
  order?: number;
}

export interface IFormExportOptionProps {
  option: ExportTemplateSchema;
  isWorking: boolean;
  onExport: (option: ExportTemplateSchema, clientKey?: string) => void;
}

const FormExportOption = ({option, isWorking, onExport}: IFormExportOptionProps) => {
  const { currentClient: client } = useClient();
  
  const handleClick = (clientKey: string) => () => {
    onExport(option, clientKey);
  }

  return (
    <>
      <Grid sm={12} container alignItems="flex-start" py={2} >
        <Grid sm={2} container alignItems="flex-start" justifyContent="center">
          <WordIcon size={48} />
        </Grid>
        <Grid sm={10} container direction="column">
          <Typography fontSize={18} fontWeight="600" mb={0.5}>{option.name}</Typography>      
          <Typography  fontSize={14} mb={0.5}>{option.description}</Typography>
          {client?.isCouple && (
            <MenuButton label="Export" variant="outlined">
              <MenuItem onClick={handleClick("client1")}>For {client.firstName}</MenuItem>
              <MenuItem onClick={handleClick("client2")}>For {client.partnerFirstName ?? "Partner"}</MenuItem>
            </MenuButton>
          )}
          {!client?.isCouple && (
            <Button onClick={() => onExport(option)} disabled={isWorking} sx={{ mt: 1, alignSelf: "flex-end" }} color="primary" size="small" variant="outlined">Export</Button>
          )}
        </Grid>      
      </Grid>
    </>
  );
};

export default FormExportOption;