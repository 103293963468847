import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { isString } from 'lodash';
import { Button, TableCell, TableRow, IconButton, List, ListItem, ListItemIcon, ListItemText, Popover, Typography, Box, alpha, Tooltip } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Assignment as AssignmentIcon, FileCopy as FileCopyIcon, FileCopy, Star, StarOutlineOutlined, ForumOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useAnchor, useHover } from 'helpers/hooks';
import { tableStyles } from 'helpers/style-helpers';
import ClientStatusChip from './chips/client-status-chip';
import { useTheme } from '@mui/styles';
import { assignClient, updateClient } from 'store/actions/attorney-actions';
import { useAttorneyContext } from '../attorney-context';
import { ClientAssignmentChip } from './chips';

function ClientListItem({item}){
  const theme = useTheme();
  const dispatch  = useDispatch();
  const navigate = useNavigate();
  const { members } = useAttorneyContext();
  // const [anchor, isOpen, onClick, onClose]  = useAnchor();
  const [isOver, onEnter, onLeave] = useHover();
  // const [isDeleting, setDeleting]   = useState(false);
  const hasShare = Boolean(item.share);
  const forms = item?.share?.forms ?? [];

  // const onToggleDelete = () => { setDeleting(!isDeleting); onClose(); }
  // const onDelete  = (e) => { dispatch(deleteSentInvite(item.id)); }
  const url = `/app/clients/${item?.id}`;

  // const assignedTo = useMemo(() => item.assignedTo ? getMember(item.assignedTo) : null, [item.assignedTo, getMember]);

  const openAssignDialog = () => {
    navigate(`${url}/documents`); 
  };

  const handleStarClick = async () => {
    const changes = { isStarred: !item.isStarred };
    const result = await dispatch(updateClient(changes, item.id));
    if(result.isOk){
      toast("Client updated", { type: "success" });
    }
  };  
  
  const handleAssign = async (e) => {
    let uid   = isString(e) ? e : e.target.value;
    if(uid === "") uid = null;
    const result = await dispatch(assignClient(item.id, uid));
    if(result.isOk){
      toast.success("Client assigned successfully");
    }
  };

  //TODO: change the menu items for accepted invitations.  Should have Archive, and something else...?
  //TODO: Status column should be info for the lawyer - perhaps whether or not they've viewed the data.  Setup a workflow for lawyer?

  return (
    <>
      {/* {isDeleting && (
        <TableRow className={clsx(classes.clientRow, classes.deleteRow)}>
          <TableCell colSpan={3}>
            Are you sure you'd like to delete the invitation for <strong>{item.firstName} {item.lastName}</strong>
          </TableCell>
          <TableCell colSpan={2} style={{textAlign: "right"}}>
            <Button color="secondary" size="small" variant="outlined" className={classes.actionButton} onClick={onDelete}>Continue</Button>
            <Button size="small" variant="outlined" onClick={onToggleDelete} className={classes.actionButton}>Cancel</Button>
          </TableCell>
        </TableRow>
      )}
      {!isDeleting && ( */}
        <TableRow onMouseEnter={onEnter} onMouseLeave={onLeave} sx={{backgroundColor: isOver ? alpha(theme.palette.primary.main, 0.1) : undefined }}>
          
          <TableCell sx={tableStyles.cell}>
            <Grid container gap={1} alignItems="center">
              <IconButton size="small" onClick={handleStarClick}>
                {!item.isStarred && <StarOutlineOutlined fontSize="small" color="disabled" />}
                {item.isStarred && <Star fontSize="small" color="primary" />}
              </IconButton>
              {hasShare && <Link to={url}>{item.firstName} {item.lastName}</Link>}
              {!hasShare && <span>{item.firstName} {item.lastName}</span>}
            </Grid>
          </TableCell>
          
          <TableCell sx={tableStyles.cell}>
            <ClientAssignmentChip assignedTo={item.assignedTo} members={members} onChange={handleAssign} variant="subtle" />
          </TableCell>

          <TableCell sx={tableStyles.cell}>
            {item.unseen ? (
              <Tooltip title="New comments">
                <Link to={`${url}/conversations`}><ForumOutlined fontSize="small" color="primary"/></Link>
                {/* <ForumOutlined fontSize="small" color="primary"/> */}
              </Tooltip>
             ) : null}
          </TableCell>
          
          <TableCell sx={tableStyles.cell}>
            {forms?.length > 0 && <FormsList forms={forms} onAssign={openAssignDialog}/>}
            {hasShare && forms?.length === 0 && (
              <Button onClick={openAssignDialog} size="small" sx={{ fontSize: "medium" }} variant="outlined" color="secondary" startIcon={<FileCopy fontSize="small"/>}>Assign</Button>)
            }
          </TableCell>
          
          <TableCell sx={tableStyles.cell}>
            <ClientStatusChip share={item?.share} />            
          </TableCell>
          
          <TableCell sx={tableStyles.cell}>
            
            {/* TODO: make this dependent on the status of the row */}
            {/* <IconButton size="small" onClick={onClick}>
              <MoreVertIcon fontSize="small"/>
            </IconButton>
            <Menu id="moreMenu" anchorEl={anchor} open={isOpen} onClose={onClose} disabled={status.isWorking}>
              <MenuItem onClick={onToggleDelete} disabled={Boolean(item.acceptedOn) || status.isWorking} fontSize="small">Delete invitation</MenuItem>
              <MenuItem disabled={Boolean(item.acceptedOn) || status.isWorking} fontSize="small">Re-send email</MenuItem>              
            </Menu> */}
          </TableCell>

        </TableRow>
      {/* )} */}      
    </>
  );
}

export default ClientListItem;

// const buildStyles   = makeStyles(theme => ({
//   deleteRow   : {
//     background    : theme.palette.error.light,
//   },
//   actionButton  : {
//     marginLeft   : theme.spacing(0.5),
//   },
//   accepted  : {
//     color       : theme.palette.primary.main,
//     fontWeight  : 600,
//   },
//   pending  : {
//     fontWeight  : 600,
//   }
// }));

const FormsList = ({ forms, onAssign }) => {
  const theme = useTheme();
  const [anchor, isOpen, onOpen, onClose, position]  = useAnchor("bottomCenter");
  const items = forms;

  return (
    <>
      {forms?.length === 0 && <span>No forms assigned</span> }
      {forms?.length > 0 && (
        <>
          <Button onMouseEnter={onOpen} onMouseLeave={onClose} onClick={onAssign} size="small" variant="outlined" color="primary" startIcon={<FileCopyIcon fontSize="small"/>}>
            <Typography fontSize="medium" aria-owns={isOpen ? 'mouse-over-popover' : undefined} aria-haspopup="true">
              {`${forms?.length} Form${forms?.length === 1 ? "" : "s"}`}
            </Typography>
          </Button>
          <Popover id="forms-popover" open={isOpen} anchorEl={anchor} onClose={onClose} sx={{pointerEvents: "none" }} disableRestoreFocus {...position} >
            <Box px={2} py={1} sx={{backgroundColor: alpha(theme.palette.primary.light, 0.2) }}>
              <List dense>
                {items?.map(form => (
                  <ListItem key={form.id} disablePadding disableGutters title={form.tt}>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      { form.id !== "more" && <AssignmentIcon fontSize="small" /> }
                      { form.id === "more" && <FileCopyIcon fontSize="small" /> }
                    </ListItemIcon>
                    <ListItemText fontSize="small">{form.formName}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Popover>
        </>
      )}
    </>
  )
}