import { finishStatus } from "redux-action-status";
import { StatusKeys } from "helpers";
import { API_URL } from "./action-helpers";

const PET_FORMS_LOADED = "CUST_FORMS_LOADED";
const PET_FORMCONFIG_ERROR = "PET_FORMCONFIG_ERROR";
const CHOOSE_FORM = "CHOOSE_FORM";

const PET_DOCUMENT_DOWNLOADED = "PET_DOCUMENT_DOWNLOADED";
const PET_DOCUMENT_SUBMITTED = "PET_DOCUMENT_SUBMITTED";
const PROVIDER_LOADED = "PROVIDER_LOADED";
const PROVIDER_LOGO_LOADED = "PROVIDER_LOGO_LOADED";

export const PETITIONER_ACTIONS = {
  PET_FORMS_LOADED,
  PET_FORMCONFIG_ERROR,
  PET_DOCUMENT_DOWNLOADED,
  PET_DOCUMENT_SUBMITTED,
  PROVIDER_LOADED, PROVIDER_LOGO_LOADED,
  CHOOSE_FORM,
};

const AccountsApiUrl = `${API_URL}/accounts`;

export const chooseForm = (formId: string) => async (dispatch: any, getState: any) => {
  return dispatch({ type: CHOOSE_FORM, formId });
};

export const submitDocument = (shareId: string, documentId: string) => async (dispatch: any, getState: any) => {
  const changes = { submittedAt: Date.now() };

  const action = {
    type: PET_DOCUMENT_SUBMITTED,    //Don't need to update the petitioner's state
    firebase: {
      type: "updateSingle",
      collection: `shares/${shareId}/documents`,
      key: documentId,
      value: changes,
    },
    //for the reducer
    shareId,
    documentId,
    statusKey: StatusKeys.shares,
  };

  return dispatch(action);
};

export const loadProviderInfo = (accountId: string) => async (dispatch: any, getState: any) => {

  const url = `${AccountsApiUrl}/${accountId}/summary`;

  const action = {
    type: PROVIDER_LOADED,
    fetch: {
      verb: "GET",
      url,
      token: true,      
    },
    statusKey: StatusKeys.petitioner,
  };

  const result = await dispatch(action);

  return result;
};

export const noProviders = () => async (dispatch: any, getState: any) => {
  return dispatch(finishStatus(StatusKeys.petitioner));
};

export const loadProviderLogo = (accountId: string, logoPath: string) => async (dispatch: any, getState: any) => {
  
  if(!accountId || !logoPath){
    console.error("loadProviderLogo: missing accountId or logoPath");
    return;
  }

  const state = getState();
  const provider = state.petitioner.providers?.find((p: any) => p.id === accountId);
  if(provider?.logoUrl) return;

  // const path = `${accountId}/files/${logoFileName}`;

  const result = await dispatch({
    type: PROVIDER_LOGO_LOADED,
    firebase: {
      type: "getFileUrl",
      path: logoPath,
    },
    accountId,
    statusKey: StatusKeys.petitioner,
  });
  
  return result;
};